import styled from "styled-components";
import { TextMd } from "./text_md";
import { isHtmlEmpty } from "../../../../../utils/utils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

function MediaComponent({ c, variant }: any) {
  const extractYoutubeVideo = (url: string) => {
    const youtubeRegex =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((?:\w|-){11})(?:&list=(\S+))?$/;
    const extracted = url.match(youtubeRegex);

    if (extracted) {
      return "//www.youtube.com/embed/" + extracted[1];
    }
    return false;
  };

  let parsedVideo = c.url;
  if (variant === "video") {
    parsedVideo = extractYoutubeVideo(c.url);
  }

  const extra: any = {};
  if (variant === "video") {
    extra.height = 400;
  }
  if (variant === "iframe") {
    extra.height = 800;
  }
  if (c.width) {
    if (variant === "image") {
      // extra.alignSelf = "center";
    }
    extra.width = Number(c.width);
  }
  if (c.height) {
    extra.height = Number(c.height);
  }

  return (
    <Container>
      {c.title && <h2 style={{ margin: 0 }}>{c.title}</h2>}
      {!isHtmlEmpty(c.description) && (
        <TextMd
          md={c.description}
          style={{ fontSize: 18, color: "gray", marginTop: 0 }}
        />
      )}
      {variant === "image" && (
        <img
          src={c.url}
          style={{
            borderRadius: 8,
            flex: 1,
            ...extra,
          }}
        />
      )}
      {variant === "video" && (
        <iframe
          src={parsedVideo}
          style={{ border: 0, borderRadius: 8, ...extra }}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      )}
      {variant === "iframe" && (
        <iframe
          src={c.url}
          style={{ border: "1px solid gray", borderRadius: 8, ...extra }}
        />
      )}
    </Container>
  );
}

export default MediaComponent;
