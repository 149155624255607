import { InsertPhotoOutlined } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { MuiColorInput } from "mui-color-input";
import Editor from "react-simple-wysiwyg";

function HeaderBuilder({ c, enableColor, onFieldChange }: any) {
  const [form, _setForm] = useState({
    ...structuredClone(c),
  });

  const setForm = (form: any) => {
    onFieldChange(form);
    _setForm(form);
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", gap: 15, padding: 15 }}
    >
      <FormControl>
        <FormLabel htmlFor="title">Titolo</FormLabel>
        <TextField
          variant="outlined"
          id="text"
          inputProps={{ maxLength: 100 }}
          value={form.title}
          onChange={(e) => setForm({ ...form, title: e.target.value })}
        />
        <FormHelperText>Massimo 100 caratteri</FormHelperText>
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="description">Descrizione</FormLabel>
        <Editor
          id="text"
          value={form.description}
          onChange={(e) => setForm({ ...form, description: e.target.value })}
        />
        <FormHelperText>Massimo 1000 caratteri</FormHelperText>
      </FormControl>
      {enableColor && (
        <FormControl>
          <FormLabel htmlFor="description">Colore del testo</FormLabel>
          <MuiColorInput
            format="hex8"
            value={form.color}
            onChange={(color) => setForm({ ...form, color })}
          />
        </FormControl>
      )}
      <FormControl variant="outlined">
        <InputLabel htmlFor="input-with-icon-adornment">
          Immagine di copertina
        </InputLabel>
        <Input
          placeholder="Inserisci una url"
          id="input-with-icon-adornment"
          value={form.image}
          onChange={(e) => setForm({ ...form, image: e.target.value })}
          startAdornment={
            <InputAdornment position="start">
              {form.image ? (
                <img src={form.image} width={50} />
              ) : (
                <InsertPhotoOutlined />
              )}
            </InputAdornment>
          }
        />
      </FormControl>
    </div>
  );
}

export default HeaderBuilder;
