import { useContext } from "react";
import { State, StateContext } from "../../state";
import { Outlet } from "react-router";

const logo = require("../../icon.png");

export const Layout = () => {
  const { username } = useContext(StateContext) as State;

  return (
    <div className="App">
      <header className="App-header">
        <div>
          <img src={logo} className="App-logo" alt="logo" />
          <p style={{ fontSize: 28 }}>GreenApes Survey ({username})</p>
        </div>
        <div></div>
        <div
          className="logout"
          onClick={() => {
            delete localStorage.auth_token;
            window.location.reload();
          }}
        >
          Logout
        </div>
      </header>
      <main className="App-main">
        <Outlet />
      </main>
    </div>
  );
};
