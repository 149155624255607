import styled from "styled-components";

const Text = styled.p`
  margin: 10px 0;
`;
const TextContainer = styled.div``;

export const TextMd = ({ md, style }: any) => {
  if (!md) {
    md = "";
  }
  let textHtml = md.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
  textHtml = textHtml.replace(/\*(.*?)\*/g, "<i>$1</i>");
  textHtml = textHtml.replace(
    /\[([^\]]+)\]\(([^\)]+)\)/g,
    '<a href="$2" target="_blank">$1</a>'
  );

  return <Text style={style} dangerouslySetInnerHTML={{ __html: textHtml }} />;
};

function TextComponent({ c, style }: any) {
  if (!style) {
    style = {};
  }
  return (
    <TextContainer>
      <TextMd md={c.text} style={style} />
    </TextContainer>
  );
}

export default TextComponent;
