import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  TextField,
} from "@mui/material";
import { useState } from "react";
import styled from "styled-components";
import Editor from "react-simple-wysiwyg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

function QuestionTextBuilder({ c, type, onFieldChange }: any) {
  const [form, _setForm] = useState({
    ...structuredClone(c),
  });

  const setForm = (form: any) => {
    onFieldChange(form);
    _setForm(form);
  };
  return (
    <Container>
      <FormControl>
        <FormLabel htmlFor="title">Domanda *</FormLabel>
        <TextField
          fullWidth
          id="title"
          value={form.title}
          onChange={(e: any) => setForm({ ...form, title: e.target.value })}
        />
      </FormControl>
      <FormControl>
        {(!type || type === "text") && (
          <>
            <FormLabel htmlFor="maxLength">
              Numero massimo di caratteri inseribili
            </FormLabel>
            <TextField
              id="maxLength"
              fullWidth
              type="number"
              value={form.maxLength}
              onChange={(e: any) =>
                setForm({ ...form, maxLength: Number(e.target.value) })
              }
            />
          </>
        )}
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="description">Descrizione</FormLabel>
        <Editor
          value={form.description}
          onChange={(e: any) =>
            setForm({ ...form, description: e.target.value })
          }
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="description">
          Feedback mostrato dopo la risposta
        </FormLabel>
        <Editor
          value={form.feedback}
          onChange={(e: any) => setForm({ ...form, feedback: e.target.value })}
        />
      </FormControl>
      {type === "number" && (
        <FormControl>
          <FormLabel htmlFor="min">Valore minimo</FormLabel>
          <TextField
            id="min"
            fullWidth
            type="number"
            value={form.min}
            onChange={(e: any) =>
              setForm({ ...form, min: Number(e.target.value) })
            }
          />
          <FormLabel htmlFor="max">Valore massimo</FormLabel>
          <TextField
            id="max"
            fullWidth
            type="number"
            value={form.max}
            onChange={(e: any) =>
              setForm({ ...form, max: Number(e.target.value) })
            }
          />
        </FormControl>
      )}
      <FormControl>
        <FormLabel htmlFor="multiple">Obbligatoria?</FormLabel>
        <FormControlLabel
          id="required"
          control={
            <Checkbox
              value={form.required}
              checked={form.required}
              onChange={(e) => setForm({ ...form, required: e.target.checked })}
            />
          }
          label={form.required}
        />
      </FormControl>
    </Container>
  );
}

export default QuestionTextBuilder;
