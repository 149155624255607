import { Button } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { ComponentCard } from "../../viewer/components/component";
import { Cancel, ContentCopy, Delete, Edit } from "@mui/icons-material";

const Container = styled.div`
  margin-bottom: 30px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  padding: 10px;
`;

export type SurveyComponentProps = {
  c: any;
  onFieldChange: (form: any) => void;
};

function SurveyComponentManager() {
  return <></>;
}

export type FormResponseType = {
  questionId: string;
  question: string;
  responses: [{ response: string; score: number }];
};

export const Component = (props: {
  c: any;
  editMode: boolean;
  idSurvey: string;
  page?: any;
  indexPage: number;
  disableDelete: boolean;
  indexComponent: number;
  noWrapPreview: boolean;
  feedbackPage?: string;
  updateSurvey: any;
  componentBackgroundColor?: string;
  onResponseChange?: (response: FormResponseType) => any;
  renderPreview: (props: any) => any;
  renderEditor?: (props: any) => any;
}) => {
  const {
    c,
    feedbackPage,
    disableDelete,
    editMode,
    idSurvey,
    indexPage,
    indexComponent,
    updateSurvey,
    noWrapPreview,
    componentBackgroundColor,
    page,
    onResponseChange,
  } = props;

  const [form, setForm] = useState({ ...structuredClone(c) });

  const [modify, setModify] = useState(false);

  const save = () => {
    updateSurvey(
      form,
      idSurvey,
      indexPage,
      indexComponent,
      false,
      feedbackPage
    );
    setModify(false);
  };

  const makeACopy = () => {
    let widgetCopy = {};
    if (page) {
      widgetCopy = { ...structuredClone(page) };
    } else {
      widgetCopy = { ...structuredClone(c) };
    }
    updateSurvey(
      widgetCopy,
      idSurvey,
      indexComponent === undefined ? props.indexPage + 1 : props.indexPage,
      indexComponent !== undefined ? indexComponent + 1 : undefined,
      true,
      props.feedbackPage
    );
  };

  const deleteComponent = () => {
    updateSurvey(
      { delete: true },
      idSurvey,
      indexPage,
      indexComponent,
      false,
      feedbackPage
    );
  };

  const wrapPreview = (props: any) => {
    const children = props.renderPreview(props);
    const childrenWithProps = React.Children.map(children, function (child) {
      return React.cloneElement(child, {
        onResultChange: (form: FormResponseType) => {
          if (onResponseChange) {
            onResponseChange(form);
          }
        },
      });
    });

    if (noWrapPreview) {
      return childrenWithProps;
    }
    return (
      <ComponentCard style={{ backgroundColor: componentBackgroundColor }}>
        {childrenWithProps}
      </ComponentCard>
    );
  };

  const wrapEditor = (props: any) => {
    const children = props.renderEditor(props);
    const childrenWithProps = React.Children.map(children, function (child) {
      return React.cloneElement(child, {
        onFieldChange: (form: any) => setForm(structuredClone(form)),
      });
    });
    return <ComponentCard>{childrenWithProps}</ComponentCard>;
  };

  return (
    <Container>
      {editMode && (
        <ButtonContainer>
          {props.renderEditor && (
            <Button
              size="small"
              onClick={() => setModify(!modify)}
              startIcon={!modify ? <Edit /> : <Cancel />}
            >
              {!modify ? "Modifica" : "Annulla"}
            </Button>
          )}
          {!modify && !disableDelete && (
            <>
              <Button onClick={makeACopy}>
                <ContentCopy /> Duplica {page && "Pagina"}
              </Button>
              <Button
                size="small"
                color="error"
                onClick={() => {
                  let _confirm = true;
                  if (page) {
                    // eslint-disable-next-line no-restricted-globals
                    _confirm = confirm(
                      "Sei sicuro di voler eliminare tutta la pagina?"
                    );
                  }
                  if (_confirm) {
                    deleteComponent();
                  }
                }}
                startIcon={<Delete />}
              >
                Elimina {page && "Pagina"}
              </Button>
            </>
          )}

          {modify && (
            <Button variant="contained" onClick={save}>
              Salva e visualizza
            </Button>
          )}
        </ButtonContainer>
      )}
      {modify ? wrapEditor(props) : wrapPreview(props)}
    </Container>
  );
};

export default SurveyComponentManager;
