import { useContext, useReducer, useState } from "react";
import { State, StateContext } from "../../../state";
import { useNavigate } from "react-router";
import {
  CheckCircleOutline,
  DesktopMac,
  EditNote,
  Email,
  ErrorOutline,
  Image,
  InputOutlined,
  MobileFriendly,
  NoteAdd,
  Numbers,
  QuestionAnswerOutlined,
  SpaceBar,
  TitleOutlined,
  Videocam,
  Visibility,
  WebAsset,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { SurveyContainer, SurveyHeader } from "./editor";
import SurveyPage from "./pages/page";
import { ValidatorForm } from "react-material-ui-form-validator";

export const feedbackPageSupportedWidgetList = [
  "header",
  "text_md",
  "media_image",
  "media_video",
  "divider",
];

function createEmptyWidget(key: string) {
  switch (key) {
    case "page":
      return {
        type: key,
        title: "Titolo Pagina",
        description: "Descrizione",
        components: [],
      };
    case "header":
      return {
        type: key,
        title: "Titolo",
        description: "Descrizione",
        color: "",
        image: "",
      };
    case "text_md":
      return {
        type: key,
        text: "testo...",
      };
    case "question_choice":
      return {
        type: key,
        title: "title",
        description: "description",
        options: [
          {
            value: "Risposta 1",
            score: 0,
          },
          {
            value: "Risposta 2",
            score: 0,
          },
        ],
      };
    case "question_imagegrid":
      return {
        type: key,
        title: "title",
        description: "description",
        options: [
          {
            value: "Risposta 1",
            image: "",
            score: 0,
          },
          {
            value: "Risposta 2",
            image: "",
            score: 0,
          },
        ],
      };
    case "question_text":
      return {
        type: key,
        title: "inerisci un testo",
        maxLength: 250,
      };
    case "question_number":
      return {
        type: key,
        title: "inserisci un numero",
        min: 1,
        max: 10,
      };
    case "question_email":
      return {
        type: key,
        title: "Inserisci il tuo indirizzo email",
      };
    case "media_image":
      return {
        type: key,
        url: "",
        title: "Descrizione della foto",
      };
    case "media_video":
      return {
        type: key,
        url: "",
        title: "Titolo video",
      };
    case "media_iframe":
      return {
        type: key,
        url: "",
        title: "Titolo",
      };
    case "divider":
      return {
        type: key,
      };
    case "divider-line":
      return {
        type: key,
      };
  }
}

export function ComponentNavBar({
  onSelected,
  exitOnly,
  showPreviews,
  onScreenSizeChange,
  survey,
}: any) {
  const [screenSize, setScreenSize] = useState<string>("desktop");

  const [dragged, dispatch] = useReducer((state: any, action: any) => {
    if (onSelected) {
      if (action.type === "drag_start") {
        onSelected(action.selected);
        return action.selected;
      }
      if (action.type === "drag_end") {
        onSelected(null);
        return null;
      }
    }
  }, null);

  const options = [
    {
      title: "Page",
      items: [
        {
          value: "page",
          title: "Page",
          icon: <NoteAdd />,
        },
      ],
    },
    {
      title: "Tipografia",
      items: [
        {
          value: "header",
          title: "Header*",
          icon: <TitleOutlined />,
        },
        {
          value: "text_md",
          title: "Testo libero*",
          icon: <EditNote />,
        },
      ],
    },
    {
      title: "Domande",
      items: [
        {
          value: "question_choice",
          title: "Risposta multipla",
          icon: <QuestionAnswerOutlined />,
        },
        {
          value: "question_imagegrid",
          title: "Risposta multipla con foto",
          icon: <Image />,
        },
        {
          value: "question_text",
          title: "Risposta a testo libero",
          icon: <InputOutlined />,
        },
        {
          value: "question_number",
          title: "Risposta numerica",
          icon: <Numbers />,
        },
        {
          value: "question_email",
          title: "Risposta email",
          icon: <Email />,
        },
      ],
    },
    {
      title: "Media",
      items: [
        {
          value: "media_image",
          title: "Foto*",
          icon: <Image />,
        },
        {
          value: "media_video",
          title: "Video*",
          icon: <Videocam />,
        },
        {
          value: "media_iframe",
          title: "Iframe",
          icon: <WebAsset />,
        },
      ],
    },
    {
      title: "Altro",
      items: [
        {
          value: "divider",
          title: "Divisorio*",
          icon: <SpaceBar />,
        },
        {
          value: "divider-line",
          title: "DivisorioLinea*",
          icon: <SpaceBar />,
        },
      ],
    },
  ];

  return (
    <>
      <span
        className="tab-scroll"
        style={{
          display: "flex",
          boxShadow: "7px 0 7px -5px #00000038",
          zIndex: 2,
        }}
      >
        <span>
          <Box
            sx={{
              width: 300,
              bgcolor: "background.paper",
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            {showPreviews && (
              <nav aria-label="secondary mailbox folders">
                <List>
                  {survey.published && (
                    <>
                      <ListItem>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            window.open("/su/" + survey.id, "_blank");
                          }}
                          size="small"
                          startIcon={<Visibility />}
                        >
                          Apri in produzione
                        </Button>
                      </ListItem>
                      <hr />
                    </>
                  )}
                  <ListItemButton
                    selected={screenSize === "desktop"}
                    onClick={() => {
                      setScreenSize("desktop");
                      onScreenSizeChange("desktop");
                    }}
                  >
                    <ListItemIcon>
                      <DesktopMac />
                    </ListItemIcon>
                    <ListItemText>Desktop</ListItemText>
                  </ListItemButton>
                  <ListItemButton
                    selected={screenSize === "mobile"}
                    onClick={() => {
                      setScreenSize("mobile");
                      onScreenSizeChange("mobile");
                    }}
                  >
                    <ListItemIcon>
                      <MobileFriendly />
                    </ListItemIcon>
                    <ListItemText>Mobile</ListItemText>
                  </ListItemButton>
                </List>
              </nav>
            )}
            {!exitOnly && (
              <>
                <nav
                  aria-label="main mailbox folders"
                  style={{
                    padding: "0 15px",
                    // height: "calc(100% - 100px)",
                    overflow: "auto",
                  }}
                >
                  {options.map((o: any, j: number) => (
                    <span key={j}>
                      <p>{o.title}</p>
                      <List>
                        {o.items.map((i: any, k: number) => (
                          <ListItem
                            key={k}
                            disablePadding
                            draggable
                            onDragStart={() =>
                              dispatch({
                                type: "drag_start",
                                selected: i.value,
                              })
                            }
                            onDragEnd={() => dispatch({ type: "drag_end" })}
                          >
                            <ListItemButton selected={dragged === i.value}>
                              <ListItemIcon>{i.icon}</ListItemIcon>
                              <ListItemText primary={i.title} />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    </span>
                  ))}
                </nav>
                <Divider />
              </>
            )}
          </Box>
        </span>
      </span>
      {!exitOnly && (
        <div
          style={{
            backgroundColor: "white",
            boxShadow: "7px 0 7px -5px #00000038",
            zIndex: 1,
          }}
        >
          <Box width={200}>
            <List>
              {survey.pages.map((page: any, index: number) => (
                <ListItemButton
                  style={{
                    backgroundColor:
                      dragged && dragged !== "page" ? "#9cd3fd" : "transparent",
                    marginBottom: 5,
                  }}
                  onDragOver={(e) => {
                    document
                      .getElementById(`PAGE_ID_INDEX_${index}`)
                      ?.scrollIntoView();
                  }}
                  onDragLeave={(e) => {
                    e.preventDefault();
                  }}
                  key={`id_list_${index}`}
                  onClick={() => {
                    document
                      .getElementById(`PAGE_ID_INDEX_${index}`)
                      ?.scrollIntoView();
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 25 }}>{index + 1}</ListItemIcon>
                  <ListItemText primary={page.title} />
                </ListItemButton>
              ))}
              <hr />
              <ListItemButton
                style={{
                  backgroundColor:
                    dragged &&
                    feedbackPageSupportedWidgetList.indexOf(dragged) !== -1
                      ? "#9cd3fd"
                      : "transparent",
                  marginBottom: 5,
                }}
                onDragOver={(e) => {
                  if (feedbackPageSupportedWidgetList.indexOf(dragged) !== -1) {
                    document
                      .getElementById(`PAGE_ID_INDEX_${survey.pages.length}`)
                      ?.scrollIntoView();
                  }
                }}
                onDragLeave={(e) => {
                  e.preventDefault();
                }}
                key={`id_list_${survey.pages.length}`}
                onClick={() => {
                  document
                    .getElementById(`PAGE_ID_INDEX_${survey.pages.length}`)
                    ?.scrollIntoView();
                }}
              >
                <ListItemIcon sx={{ minWidth: 35 }}>
                  <CheckCircleOutline />
                </ListItemIcon>
                <ListItemText>
                  {survey.isQuiz ? "Quiz passato*" : "Survey completato*"}
                </ListItemText>
              </ListItemButton>
              {survey.isQuiz && (
                <ListItemButton
                  style={{
                    backgroundColor:
                      dragged &&
                      feedbackPageSupportedWidgetList.indexOf(dragged) !== -1
                        ? "#9cd3fd"
                        : "transparent",
                    marginBottom: 5,
                  }}
                  onDragOver={(e) => {
                    if (
                      feedbackPageSupportedWidgetList.indexOf(dragged) !== -1
                    ) {
                      document
                        .getElementById(
                          `PAGE_ID_INDEX_${survey.pages.length + 1}`
                        )
                        ?.scrollIntoView();
                    }
                  }}
                  onDragLeave={(e) => {
                    e.preventDefault();
                  }}
                  key={`id_list_${survey.pages.length + 1}`}
                  onClick={() => {
                    document
                      .getElementById(
                        `PAGE_ID_INDEX_${survey.pages.length + 1}`
                      )
                      ?.scrollIntoView();
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 35 }}>
                    <ErrorOutline />
                  </ListItemIcon>
                  <ListItemText>Quiz fallito*</ListItemText>
                </ListItemButton>
              )}
            </List>
          </Box>
        </div>
      )}
    </>
  );
}

const EditorView = ({ survey, selectedWidget, setSelectedWidget }: any) => {
  const { updateSurvey } = useContext(StateContext) as State;

  return (
    <div className="editor">
      <ComponentNavBar
        survey={survey}
        onSelected={(key: string) => setSelectedWidget(createEmptyWidget(key))}
      />
      <div className="survey-board tab-scroll">
        <SurveyContainer>
          <SurveyHeader survey={survey} />
          <ValidatorForm
            onSubmit={(e) => console.log("FORM SUBMIT", e)}
            onError={(errors) => console.log("FORM ERRORS", errors)}
          >
            <div>
              {survey.pages.map((c: any, indexPage: number) => {
                return (
                  <SurveyPage
                    key={`page_${indexPage}`}
                    editMode
                    selectedWidget={selectedWidget}
                    page={c}
                    indexPage={indexPage}
                    idSurvey={survey.id}
                    survey={survey}
                    updateSurvey={updateSurvey}
                  />
                );
              })}
              <SurveyPage
                editMode
                feedbackPage="success_page"
                supportedList={feedbackPageSupportedWidgetList}
                selectedWidget={selectedWidget}
                page={survey.success_page}
                indexPage={survey.pages.length}
                idSurvey={survey.id}
                survey={survey}
                updateSurvey={updateSurvey}
              />
              {survey.isQuiz && (
                <SurveyPage
                  editMode
                  feedbackPage="error_page"
                  supportedList={feedbackPageSupportedWidgetList}
                  selectedWidget={selectedWidget}
                  page={survey.error_page}
                  indexPage={survey.pages.length + 1}
                  idSurvey={survey.id}
                  survey={survey}
                  updateSurvey={updateSurvey}
                />
              )}
            </div>
          </ValidatorForm>
        </SurveyContainer>
      </div>
    </div>
  );
};

export default EditorView;
