import Box from "@mui/material/Box";
import React, { useContext } from "react";
import { State, StateContext } from "../../../state";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";
import PreviewIcon from "@mui/icons-material/Preview";
import { Chip, Tab, Tabs } from "@mui/material";
import { ArrowBack, QuestionAnswer } from "@mui/icons-material";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router";
import { ConfigView } from "./ConfigView";
import EditorView from "./EditorView";
import PreviewView from "./PreviewView";
import { TextMd } from "../viewer/components/typografy/text_md";
import { Results } from "./ResultsView";

require("./editor.css");

export const SurveyContainer = styled.div`
  max-width: 700px;
  width: 100%;
  margin: auto;
  margin-bottom: 40px;
  padding: 20px;
  position: relative;
`;

const FakeTab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  padding: 10px;
  padding-right: 220px;
`;

export function SurveyHeader({ survey, currentPage, isMobile }: any) {
  let style: any = {};
  // if (isMobile) {
  //   style = {
  //     padding: 20,
  //     margin: -20,
  //     borderRadius: 0,
  //     backgroundColor: "white",
  //     marginBottom: 20,
  //   };
  // }
  if (survey.title || survey.description) {
    return (
      <div className="survey-header" style={style}>
        <div>
          <h1>{survey.title}</h1>
          <TextMd md={survey.description} />
        </div>
        {currentPage !== undefined && survey.pages.length > 1 && (
          <Chip
            label={`Pagina ${currentPage + 1} / ${survey.pages.length}`}
            variant="outlined"
            style={{ background: "white", marginLeft: 15 }}
          />
        )}
      </div>
    );
  } else {
    return <></>;
  }
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function MainBoard({ survey, view }: any) {
  const [value, setValue] = React.useState(view === "preview" ? 1 : 0);
  const [selectedWidget, setSelectedWidget] = React.useState<any>(null);
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div style={{ flex: 1 }}>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            background: "white",
          }}
        >
          <FakeTab
            onClick={() => {
              navigate("/");
            }}
          >
            <ArrowBack /> indietro
          </FakeTab>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Editor"
              iconPosition="start"
              icon={<EditIcon />}
              {...a11yProps(0)}
            />
            <Tab
              label="Preview"
              iconPosition="start"
              icon={<PreviewIcon />}
              {...a11yProps(1)}
            />
            <Tab
              disabled={survey && !survey.published}
              label="Results"
              iconPosition="start"
              icon={<QuestionAnswer />}
              {...a11yProps(2)}
            />
            <Tab
              label="Settings"
              iconPosition="start"
              icon={<SettingsIcon />}
              {...a11yProps(3)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <EditorView
            survey={survey}
            selectedWidget={selectedWidget}
            setSelectedWidget={(s: any) => setSelectedWidget(s)}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <PreviewView survey={survey} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Results survey={survey} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <ConfigView survey={survey} />
        </CustomTabPanel>
      </Box>
    </div>
  );
}

const SurveyEditor = () => {
  const params = useParams();
  const { surveys } = useContext(StateContext) as State;
  const searchParams = new URLSearchParams(document.location.search);
  const view = searchParams.get("view");
  const survey = surveys.find((s: any) => String(s.id) === params.id);
  if (!survey) {
    return <></>;
  }
  return <MainBoard survey={survey} view={view} />;
};

export default SurveyEditor;
