import { Add, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { State, StateContext } from "../../state";

export const GroupList = ({ selected, onGoupSelected, onDrop }: any) => {
  const [openModalNewGroup, setOpenModalNewGroup] = React.useState(false);
  const [selectedGroup, setSelectedGroup] = useState(undefined);
  const [newGroupTitle, setNewGroupTitle] = React.useState("");

  const { groups, addGroup, removeGroup, moveSurveyInGroup } = useContext(
    StateContext
  ) as State;

  const create = () => {
    addGroup(newGroupTitle);
    setNewGroupTitle("");
    setOpenModalNewGroup(false);
  };

  const remove = (e: any, group: any) => {
    e.preventDefault();
    e.stopPropagation();
    // eslint-disable-next-line no-restricted-globals
    const res = confirm(
      `Sei sicuro di voler eliminare la lista "${group.title}". Gli elementi al suo interno verranno spostati in DEFAULT`
    );
    if (res) {
      removeGroup(group.id);
    }
  };

  const select = (groupId: any) => {
    setSelectedGroup(groupId);
    onGoupSelected(groupId);
  };

  const defaultGroup = {
    // NON C'è ID apposta, così viene settato undefined
    title: "DEFAULT",
  };

  const groupStyle = (groupId: any) => {
    const defaultColor = selectedGroup === groupId ? "#bcbcbc" : "transparent";
    if (groupId) {
      return {
        background: selected ? "#a2e8a2" : defaultColor,
      };
    } else {
      return {
        borderBottom: "1px solid #bcbcbc",
        marginBottom: 10,
        cursor: "pointer",
        background: selected ? "#a2e8a2" : defaultColor,
      };
    }
  };

  return (
    <>
      <Box
        sx={{
          width: 200,
          bgcolor: "background.paper",
          display: "flex",
          flexDirection: "column",
          height: 515, // se tolgo l'altezza mi prende quella della pagina
        }}
      >
        <nav aria-label="secondary mailbox folders" style={{ flex: 1 }}>
          <List>
            <div style={{ flex: 1 }}>
              {[defaultGroup, ...groups].map((group: any) => (
                <ListItem
                  key={group.id ? group.id : "DEFAULT"}
                  id={`group_id_${group.id}`}
                  style={groupStyle(group.id)}
                  onDrop={(e: any) => {
                    const div = document.getElementById(`group_id_${group.id}`);
                    if (div) {
                      div.style.background = "transparent";
                    }
                    // eslint-disable-next-line no-restricted-globals
                    const res = confirm(
                      `sei sicuro di voler spostare "${selected.title}" nella lista "${group.title}"?`
                    );
                    if (res) {
                      moveSurveyInGroup(group.id, selected.id);
                    }
                    onDrop();
                  }}
                  onDragOver={(e: any) => {
                    e.preventDefault();
                    const div = document.getElementById(`group_id_${group.id}`);
                    if (div) {
                      div.style.background = "#00e600";
                    }
                  }}
                  onDragLeave={(e: any) => {
                    e.preventDefault();
                    const div = document.getElementById(`group_id_${group.id}`);
                    if (div) {
                      div.style.background = "#a2e8a2";
                    }
                  }}
                  secondaryAction={
                    group.id &&
                    !selected && (
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={(e) => remove(e, group)}
                      >
                        <Delete />
                      </IconButton>
                    )
                  }
                >
                  {selected && (
                    <ListItemIcon>
                      <Add />
                    </ListItemIcon>
                  )}
                  <ListItemText
                    onClick={() => select(group.id)}
                    style={{ cursor: "pointer" }}
                  >
                    {group.title}
                  </ListItemText>
                </ListItem>
              ))}
            </div>
          </List>
        </nav>
        <ListItemButton
          style={{ flex: 0, borderTop: "1px solid #bcbcbc" }}
          onClick={() => setOpenModalNewGroup(true)}
        >
          <ListItemIcon>
            <Add />
          </ListItemIcon>
          <ListItemText>Nuovo gruppo</ListItemText>
        </ListItemButton>
      </Box>
      <Modal
        open={openModalNewGroup}
        onClose={() => setOpenModalNewGroup(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Inserisci un nuovo gruppo
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            I gruppi ti permettono di organizzare i tuoi survey/quiz per cliente
          </Typography>
          <div>
            <br />
            <FormControl fullWidth>
              <FormLabel htmlFor="groupTitle">Nome del gruppo</FormLabel>
              <TextField
                variant="outlined"
                id="groupTitle"
                multiline
                inputProps={{ maxLength: 50 }}
                value={newGroupTitle}
                onChange={(e) => setNewGroupTitle(e.target.value)}
              />
              <FormHelperText>Massimo 50 caratteri</FormHelperText>
              <br />
            </FormControl>
            <Button
              variant="contained"
              color="success"
              onClick={create}
              fullWidth
              disabled={!newGroupTitle}
            >
              Crea
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};
