import React, { useContext, useReducer, useState } from "react";
import { State, StateContext } from "../../state";
import {
  Button,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  Add,
  Code,
  ContentCopy,
  Delete,
  DragHandle,
  Edit,
  Link,
  MoreVert,
  Public,
  PublicOff,
  Visibility,
} from "@mui/icons-material";
import { useNavigate } from "react-router";
import { GroupList } from "./groups";

function SurveyCard({ survey, onOpen }: any) {
  const { updateSurvey } = useContext(StateContext) as State;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "grab",
        gap: 10,
      }}
      onClick={(e: any) => {
        e.preventDefault();
        onOpen();
      }}
    >
      <DragHandle />
      <ListItemText primary={survey.title} style={{ flex: 1 }} />
      <IconButton
        edge="end"
        aria-label="Modifica"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onOpen();
        }}
        color="warning"
      >
        <Edit />
      </IconButton>
      <IconButton
        edge="end"
        aria-label="Visualizza"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onOpen("preview");
        }}
        color="info"
      >
        <Visibility />
      </IconButton>
      <IconButton
        edge="end"
        aria-label="Elimina"
        onClick={(e) => {
          // eslint-disable-next-line no-restricted-globals
          const ok = confirm(
            "Sei sicuro di voler cancelalre il survey '" + survey.title
          );
          if (ok) {
            updateSurvey({ delete: true }, survey.id);
          }
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Delete color="error" />
      </IconButton>
      <SurveyMenu survey={survey} />
    </div>
  );
}

function SurveyMenu({ survey }: any) {
  const { updateSurvey } = useContext(StateContext) as State;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  const publish = (e: any, published: boolean) => {
    updateSurvey({ ...survey, published }, survey.id);
    handleClose(e);
  };

  const copyPublicLink = (e: any) => {
    const link = `${window.location.href}su/${survey.id}`;
    navigator.clipboard.writeText(link);
    alert("Link copiato negli appunti");
    window.open(link, "_blank");
    handleClose(e);
  };

  const copyPublicId = (e: any) => {
    alert("ID copiato negli appunti");
    navigator.clipboard.writeText(survey.id);
    handleClose(e);
  };

  const makeACopy = (e: any, survey: any) => {
    const copy = { ...survey };
    copy.id = new Date().getTime();
    copy.title = `${copy.title} (Copy)`;
    copy.published = false;
    updateSurvey(copy);
    handleClose(e);
  };

  return (
    <div>
      <IconButton
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {survey.published && (
          <>
            <MenuItem onClick={copyPublicId}>
              <ListItemIcon>
                <Code fontSize="small" />
              </ListItemIcon>
              <ListItemText>Copia ID per integrazione</ListItemText>
            </MenuItem>
            <MenuItem onClick={copyPublicLink}>
              <ListItemIcon>
                <Link fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                Copia link e apri sondaggio di produzione
              </ListItemText>
            </MenuItem>
            <MenuItem onClick={(e) => publish(e, false)}>
              <ListItemIcon>
                <PublicOff fontSize="small" />
              </ListItemIcon>
              <ListItemText>Rimuovi pubblicazione</ListItemText>
            </MenuItem>
          </>
        )}
        {!survey.published && (
          <MenuItem onClick={(e) => publish(e, true)}>
            <ListItemIcon>
              <Public fontSize="small" />
            </ListItemIcon>
            <ListItemText>Rendi pubblico</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={(e) => makeACopy(e, survey)}>
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>Crea una copia</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}

const SurveyList = () => {
  const { surveys, updateSurvey } = useContext(StateContext) as State;
  const [selectedGroup, setSelectedGroup] = useState(undefined);
  const navigate = useNavigate();

  const [dragged, dispatch] = useReducer((state: any, action: any) => {
    if (action.type === "drag_start") {
      return action.selected;
    }
    if (action.type === "drag_end") {
      return null;
    }
  }, null);

  const newSurvey = () => {
    return {
      groupId: selectedGroup,
      title: `Survey senza nome ${new Date().getTime()}`,
      description: "descrizione",
      isQuiz: false,
      saveUserResponseToDatabase: false,
      pages: [
        {
          id: 1,
          title: "",
          description: "",
          components: [],
        },
      ],
      success_page: {
        title: "Congratulazioni",
        description: "descrizione...",
        components: [],
      },
      error_page: {
        title: "Non hai superato il quiz",
        description: "descrizione...",
        components: [],
      },
    };
  };

  const renderSurvey = (survey: any) => {
    return (
      <div
        draggable
        style={{
          padding: 15,
          borderRadius: 8,
          border: "1px solid gray",
          marginBottom: 20,
          background: "white",
          opacity: dragged && dragged.id === survey.id ? 0.5 : 1,
        }}
        key={survey.id}
        onDragStart={(e) =>
          dispatch({
            type: "drag_start",
            selected: survey,
          })
        }
        onDragEnd={() => dispatch({ type: "drag_end" })}
      >
        <SurveyCard
          onOpen={(view: string) => {
            const preview = view ? `?view=${view}` : "";
            navigate(`/edit/survey/${survey.id}${preview}`);
          }}
          survey={survey}
          key={survey.id}
        />
      </div>
    );
  };

  const filterGroup = (survey: any) => survey.groupId === selectedGroup;

  return (
    <div style={{ display: "flex", padding: 20 }}>
      <GroupList
        selected={dragged}
        onGoupSelected={(groupId: any) => setSelectedGroup(groupId)}
        onDrop={() => dispatch({ type: "drag_end" })}
      />
      <div className="survey-list">
        <div>
          <div>
            <h1>I miei survey</h1>
            <List sx={{ width: "100%", maxWidth: 400 }}>
              {surveys
                .filter(
                  (survey: any) =>
                    survey.published && !survey.isQuiz && filterGroup(survey)
                )
                .map((survey: any) => renderSurvey(survey))}
            </List>
          </div>
          <div>
            <h1>I miei quiz</h1>
            <List sx={{ width: "100%", maxWidth: 400 }}>
              {surveys
                .filter(
                  (survey: any) =>
                    survey.published && survey.isQuiz && filterGroup(survey)
                )
                .map((survey: any) => renderSurvey(survey))}
            </List>
          </div>
        </div>
        <hr style={{ width: "100%" }} />
        <div>
          <div>
            <h2>Bozze (survey)</h2>
            <List sx={{ width: "100%", maxWidth: 400 }}>
              {surveys
                .filter(
                  (survey: any) =>
                    !survey.published && !survey.isQuiz && filterGroup(survey)
                )
                .map((survey: any) => renderSurvey(survey))}
            </List>
          </div>
          <div>
            <h2>Bozze (quiz)</h2>
            <List sx={{ width: "100%", maxWidth: 400 }}>
              {surveys
                .filter(
                  (survey: any) =>
                    !survey.published && survey.isQuiz && filterGroup(survey)
                )
                .map((survey: any) => renderSurvey(survey))}
            </List>
          </div>
        </div>
        <div style={{ flex: 0 }}>
          <Button
            size="large"
            variant="contained"
            color="info"
            startIcon={<Add />}
            onClick={() => {
              updateSurvey(newSurvey());
            }}
          >
            Nuovo survey / quiz
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SurveyList;
