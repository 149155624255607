import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ComponentNavBar } from "./EditorView";
import { State, StateContext } from "../../../state";
import {
  DesktopMacOutlined,
  InsertPhotoOutlined,
  MobileFriendly,
} from "@mui/icons-material";
import { MuiColorInput } from "mui-color-input";
import { useContext, useState } from "react";
import styled from "styled-components";
import Editor from "react-simple-wysiwyg";

const Contain = styled.div`
  padding: 15px;
  border-radius: 5px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const ConfigView = ({ survey }: any) => {
  const { updateSurvey } = useContext(StateContext) as State;

  const [form, setForm] = useState({
    ...survey,
  });

  const previewImageStyle = (img: string, size: string) => {
    const style: any = {
      backgroundImage: `url(${img})`,
      width: 500,
      height: 350,
    };
    if (size) {
      style.backgroundSize =
        size === "cover" || size === "coverfixed" ? "cover" : "";
      if (size === "cover") {
        style.backgroundAttachment = "fixed";
      }
      if (size === "pattern300") {
        style.backgroundSize = "300px";
      }
      if (size === "pattern100") {
        style.backgroundSize = "100px";
      }
      if (size === "pattern100%") {
        style.backgroundSize = "100%";
      }
    }
    return style;
  };

  return (
    <div className="editor">
      <ComponentNavBar exitOnly survey={survey} />
      <div className="survey-board tab-scroll">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 15,
            padding: 15,
            maxWidth: 800,
            margin: "auto",
          }}
        >
          <h3>ID: {form.id}</h3>
          <Contain>
            <TextField
              id="titles"
              variant="outlined"
              label="Nome del survey"
              value={form.title}
              onChange={(e) => setForm({ ...form, title: e.target.value })}
            />
            <FormLabel htmlFor="description">Descrizione</FormLabel>
            <Editor
              id="description"
              value={form.description}
              onChange={(e) =>
                setForm({ ...form, description: e.target.value })
              }
            />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.isQuiz}
                    onChange={(e) =>
                      setForm({ ...form, isQuiz: e.target.checked })
                    }
                  />
                }
                label="Spunta se è un quiz"
              />
            </FormGroup>
            {form.isQuiz && (
              <TextField
                variant="outlined"
                type="number"
                label="Punteggio minimo per passare il quiz"
                inputProps={{ min: 0, max: 10000000 }}
                value={form.minRateToSuccess}
                onChange={(e) =>
                  setForm({ ...form, minRateToSuccess: Number(e.target.value) })
                }
              />
            )}
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.saveUserResponseToDatabase}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        saveUserResponseToDatabase: e.target.checked,
                      })
                    }
                  />
                }
                label="Salva i data su greenapes Legandoli all'utente!"
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.published}
                    onChange={(e) =>
                      setForm({ ...form, published: e.target.checked })
                    }
                  />
                }
                label="PUBBLICO"
              />
              <FormHelperText>
                Spuntare per rendere pubblico questo survey/quiz
              </FormHelperText>
            </FormGroup>
          </Contain>
          <Contain>
            <h2>
              <DesktopMacOutlined /> Sfondo su computer
            </h2>
            <FormControl variant="outlined">
              <InputLabel htmlFor="backgroundImage">
                Immagine di sfondo DESKTOP
              </InputLabel>
              <Input
                placeholder="Inserisci la url dell'immagine"
                id="backgroundImage"
                value={form.backgroundImage}
                onChange={(e) =>
                  setForm({ ...form, backgroundImage: e.target.value })
                }
                startAdornment={
                  <InputAdornment position="start">
                    {!form.backgroundImage && <InsertPhotoOutlined />}
                  </InputAdornment>
                }
              />
              {form.backgroundImage && (
                <div
                  style={previewImageStyle(
                    form.backgroundImage,
                    form.backgroundImageSize
                  )}
                />
              )}
            </FormControl>
            {form.backgroundImage && (
              <FormControl fullWidth>
                <InputLabel id="backgroundImageSize">
                  Dimensioni immagine di sfondo DESKTOP
                </InputLabel>
                <Select
                  variant="standard"
                  labelId="backgroundImageSize"
                  value={form.backgroundImageSize}
                  onChange={(e) =>
                    setForm({ ...form, backgroundImageSize: e.target.value })
                  }
                >
                  <MenuItem value="coverfixed">
                    Tutto schermo (non si ripete e non scrolla)
                  </MenuItem>
                  <MenuItem value="cover">
                    Tutto schermo (non si ripete ma scrolla)
                  </MenuItem>
                  <MenuItem value="pattern100">
                    Ripetuta (si ripete 100px a pezzo)
                  </MenuItem>
                  <MenuItem value="pattern300">
                    Ripetuta (si ripete 300px a pezzo)
                  </MenuItem>
                  <MenuItem value="pattern100%">
                    Ripetuta (si ripete 100% larghezza schermo a pezzo)
                  </MenuItem>
                  <MenuItem value="pattern">
                    Ripetuta (si ripete con la dimensione originaledell'immagine
                    scelta)
                  </MenuItem>
                </Select>
              </FormControl>
            )}
            <h2>
              <MobileFriendly /> Sfondo sul Telefono
            </h2>

            <FormControl variant="outlined">
              <InputLabel htmlFor="backgroundImageMobile">
                Immagine di sfondo MOBILE
              </InputLabel>
              <Input
                placeholder="Inserisci la url dell'immagine"
                id="backgroundImageMobile"
                value={form.backgroundImageMobile}
                onChange={(e) =>
                  setForm({ ...form, backgroundImageMobile: e.target.value })
                }
                startAdornment={
                  <InputAdornment position="start">
                    {!form.backgroundImageMobile && <InsertPhotoOutlined />}
                  </InputAdornment>
                }
              />
              {form.backgroundImageMobile && (
                <div
                  style={{
                    ...previewImageStyle(
                      form.backgroundImageMobile,
                      form.backgroundImageSizeMobile
                    ),
                    width: 250,
                  }}
                />
              )}
            </FormControl>
            {form.backgroundImageMobile && (
              <FormControl fullWidth>
                <InputLabel id="backgroundImageSizeMobile">
                  Dimensioni immagine di sfondo MOBILE
                </InputLabel>
                <Select
                  variant="standard"
                  labelId="backgroundImageSizeMobile"
                  value={form.backgroundImageSizeMobile}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      backgroundImageSizeMobile: e.target.value,
                    })
                  }
                >
                  <MenuItem value="coverfixed">
                    Tutto schermo (non si ripete e non scrolla)
                  </MenuItem>
                  <MenuItem value="cover">
                    Tutto schermo (non si ripete ma scrolla)
                  </MenuItem>
                  <MenuItem value="pattern100">
                    Ripetuta (si ripete 100px a pezzo)
                  </MenuItem>
                  <MenuItem value="pattern300">
                    Ripetuta (si ripete 300px a pezzo)
                  </MenuItem>
                  <MenuItem value="pattern100%">
                    Ripetuta (si ripete 100% larghezza schermo a pezzo)
                  </MenuItem>
                  <MenuItem value="pattern">
                    Ripetuta (si ripete con la dimensione originaledell'immagine
                    scelta)
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          </Contain>
          {!form.backgroundImage && !form.backgroundImageMobile && (
            <Contain>
              <h2>
                <MobileFriendly /> <DesktopMacOutlined /> Colore di sfondo
              </h2>
              <FormControl>
                <FormLabel htmlFor="backgroundColor">
                  Colore di sfondo
                </FormLabel>
                <MuiColorInput
                  id="backgroundColor"
                  format="hex8"
                  value={form.backgroundColor}
                  onChange={(backgroundColor) =>
                    setForm({ ...form, backgroundColor })
                  }
                />
              </FormControl>
            </Contain>
          )}
          <Contain>
            <h2>Pagine</h2>
            <FormControl>
              <FormLabel htmlFor="surveyBackgroundColor">
                Colore di sfondo delle pagine
              </FormLabel>
              <MuiColorInput
                id="surveyBackgroundColor"
                format="hex8"
                value={form.surveyBackgroundColor}
                onChange={(surveyBackgroundColor) =>
                  setForm({ ...form, surveyBackgroundColor })
                }
              />
            </FormControl>
          </Contain>

          <Contain>
            <h2>Domande</h2>
            <FormControl>
              <FormLabel htmlFor="surveyBackgroundColor">
                Colore di sfondo di ogni domanda/contenuto
              </FormLabel>
              <MuiColorInput
                id="componentBackgroundColor"
                format="hex8"
                value={form.componentBackgroundColor}
                onChange={(componentBackgroundColor) =>
                  setForm({ ...form, componentBackgroundColor })
                }
              />
            </FormControl>
          </Contain>

          <Contain>
            <h2>Colori pulsanti e barra di avanzamento</h2>
            <FormControl>
              <FormLabel htmlFor="surveyButtonsColor">
                Colore dei pulsanti di "avanti" e "completa"
              </FormLabel>
              <MuiColorInput
                id="surveyButtonsColor"
                format="hex8"
                value={form.surveyButtonsColor}
                onChange={(surveyButtonsColor) =>
                  setForm({ ...form, surveyButtonsColor })
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="surveyBarColor1">
                Colore di SFONDO della barra di avanzamento (per i multi pagina)
              </FormLabel>
              <MuiColorInput
                id="surveyBarColor1"
                format="hex8"
                value={form.surveyBarColor1}
                onChange={(surveyBarColor1) =>
                  setForm({ ...form, surveyBarColor1 })
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="surveyBarColor2">
                Colore della barra di avanzamento (per i multi pagina)
              </FormLabel>
              <MuiColorInput
                id="surveyBarColor2"
                format="hex8"
                value={form.surveyBarColor2}
                onChange={(surveyBarColor2) =>
                  setForm({ ...form, surveyBarColor2 })
                }
              />
            </FormControl>
          </Contain>

          <Button
            variant="contained"
            size="large"
            onClick={() => updateSurvey(form, survey.id)}
          >
            Salva
          </Button>
        </div>
      </div>
    </div>
  );
};
