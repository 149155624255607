import { useState } from "react";
import { SurveyComponentProps } from "../component";
import {
  FormControl,
  FormLabel,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { InsertPhotoOutlined } from "@mui/icons-material";
import Editor from "react-simple-wysiwyg";

function MediaBuilder({ c, onFieldChange }: SurveyComponentProps) {
  const [form, _setForm] = useState({
    ...structuredClone(c),
  });

  const setForm = (form: any) => {
    onFieldChange(form);
    _setForm(form);
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", gap: 15, padding: 15 }}
    >
      <FormControl>
        <FormLabel htmlFor="img_title">Titolo</FormLabel>
        <TextField
          variant="outlined"
          id="img_title"
          value={form.title}
          onChange={(e) => setForm({ ...form, title: e.target.value })}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="img_desc">Descrizione</FormLabel>
        <Editor
          id="img_desc"
          value={form.description}
          onChange={(e) => setForm({ ...form, description: e.target.value })}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="img_width">Largezza</FormLabel>
        <TextField
          id="img_width"
          value={form.width}
          type="number"
          inputProps={{ min: 0, max: 500 }}
          onChange={(e) => setForm({ ...form, width: Number(e.target.value) })}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="img_height">Altezza</FormLabel>
        <TextField
          id="img_height"
          type="number"
          value={form.height}
          inputProps={{ min: 0, max: 1000 }}
          onChange={(e) => setForm({ ...form, height: Number(e.target.value) })}
        />
      </FormControl>
      <FormControl variant="outlined">
        <InputLabel htmlFor="input-with-icon-adornment">Url</InputLabel>
        <Input
          placeholder="Inserisci una url"
          id="input-with-icon-adornment"
          value={form.url}
          onChange={(e) => setForm({ ...form, url: e.target.value })}
          startAdornment={
            <InputAdornment position="start">
              {form.image ? (
                <img src={form.image} width={50} />
              ) : (
                <InsertPhotoOutlined />
              )}
            </InputAdornment>
          }
        />
      </FormControl>
    </div>
  );
}

export default MediaBuilder;
