import styled from "styled-components";
import { TextMd } from "./text_md";
import { isHtmlEmpty } from "../../../../../utils/utils";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  margin: -20px;
  gap: 20px;
`;

const TitleBig = styled.p`
  font-size: 44px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 15px;
`;

function HeaderComponent({ c }: any) {
  return (
    <Container>
      {c.image && <img src={c.image} style={{ width: 200, borderRadius: 8 }} />}
      <div>
        <TitleBig>{c.title}</TitleBig>
        {!isHtmlEmpty(c.description) && <TextMd md={c.description} />}
      </div>
    </Container>
  );
}

export default HeaderComponent;
