import {
  Checkbox,
  FormControlLabel,
  FormControl,
  Button,
  FormLabel,
  TextField,
} from "@mui/material";
import { useState } from "react";
import Editor from "react-simple-wysiwyg";

export function QuestionChoicheBuilder({
  c,
  supportImage,
  onFieldChange,
}: any) {
  const [form, _setForm] = useState({
    ...structuredClone(c),
  });

  const setForm = (form: any) => {
    onFieldChange(form);
    _setForm(form);
  };

  const newOption = {
    value: "",
    image: "",
    score: 0,
  };

  const updateOption = (value: any, field: string, index: number) => {
    setForm(
      structuredClone({
        ...form,
        options: form.options.map((o2: any, i2: number) => {
          if (i2 === index) {
            o2[field] = value;
          }
          return o2;
        }),
      })
    );
  };

  return (
    <div className="form flex-lg-col">
      <FormControl>
        <FormLabel htmlFor="title">Domanda *</FormLabel>
        <TextField
          variant="outlined"
          id="title"
          value={form.title}
          onChange={(e) => setForm({ ...form, title: e.target.value })}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="description">Descrizione</FormLabel>
        <Editor
          id="description"
          value={form.description}
          onChange={(e) => setForm({ ...form, description: e.target.value })}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="description">
          Feedback mostrato dopo la risposta
        </FormLabel>
        <Editor
          id="feedback"
          value={form.feedback}
          onChange={(e: any) => setForm({ ...form, feedback: e.target.value })}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="multiple">Risposta multipla?</FormLabel>
        <FormControlLabel
          id="multiple"
          control={
            <Checkbox
              value={form.multiple}
              checked={form.multiple}
              onChange={(e) => setForm({ ...form, multiple: e.target.checked })}
            />
          }
          label={form.multiple}
        />
        {form.multiple && (
          <>
            <FormLabel htmlFor="multimin">
              Seleziona almeno N risposte
            </FormLabel>
            <TextField
              variant="outlined"
              id="multimax"
              value={form.multipleMin}
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
              onChange={(e) =>
                setForm({ ...form, multipleMin: Number(e.target.value) })
              }
            />
            <FormLabel htmlFor="multimax">
              Seleziona al massimo N risposte
            </FormLabel>
            <TextField
              variant="outlined"
              id="multimax"
              value={form.multipleMax}
              type="number"
              InputProps={{ inputProps: { min: 2, max: form.options.length } }}
              onChange={(e) =>
                setForm({ ...form, multipleMax: Number(e.target.value) })
              }
            />
          </>
        )}
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="multiple">Obbligatoria?</FormLabel>
        <FormControlLabel
          id="required"
          control={
            <Checkbox
              value={form.required}
              checked={form.required}
              onChange={(e) => setForm({ ...form, required: e.target.checked })}
            />
          }
          label={form.required}
        />
      </FormControl>
      {form.options.map((o: any, i: number) => (
        <div className="flex-lg-row" style={{ marginLeft: 20 }} key={i}>
          <FormControl>
            <FormLabel htmlFor={`value_${form.options[i].value}`}>
              Testo
            </FormLabel>
            <TextField
              variant="outlined"
              id={`value_${form.options[i].value}`}
              value={form.options[i].value}
              onChange={(e) => updateOption(e.target.value, "value", i)}
            />
          </FormControl>
          {supportImage && (
            <FormControl>
              <FormLabel htmlFor={`img_${form.options[i].value}`}>
                Url immagine
              </FormLabel>
              <TextField
                variant="outlined"
                id={`img_${form.options[i].value}`}
                value={form.options[i].image}
                onChange={(e) => updateOption(e.target.value, "image", i)}
              />
            </FormControl>
          )}

          <FormControl>
            <FormLabel htmlFor={`score_${form.options[i].value}`}>
              Score
            </FormLabel>
            <TextField
              variant="outlined"
              id={`score_${form.options[i].value}`}
              value={form.options[i].score}
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              onChange={(e) => updateOption(Number(e.target.value), "score", i)}
            />
          </FormControl>
          <Button
            variant="outlined"
            onClick={() => {
              setForm({
                ...form,
                options: form.options.filter((a: any) => a.value !== o.value),
              });
            }}
          >
            Elimina
          </Button>
        </div>
      ))}
      <div>
        <Button
          variant="contained"
          onClick={() =>
            setForm({ ...form, options: [...form.options, { ...newOption }] })
          }
        >
          Aggiungi opzione
        </Button>
      </div>
    </div>
  );
}
