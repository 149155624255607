const LOCALSTORAGE = false;

let _fetch: (url: string, data: any) => Promise<any>;
let thenJson = (data: any) => {
  return data;
};
let catchLogout = (err: any) => {
  return err;
};

if (LOCALSTORAGE) {
  _fetch = (url: string, config: any) => {
    const myPromise = new Promise((resolve, reject) => {
      const resouce = url.split("3000/")[1].split("/")[0];
      const rand = Math.floor(Math.random() * (1500 - 500 + 1) + 500);
      if (config.method === "GET") {
        setTimeout(() => {
          let resp = [];
          if (localStorage[resouce]) {
            try {
              resp = JSON.parse(localStorage[resouce]);
            } catch (e) {
              resp = [];
            }
          }
          resolve(resp);
        }, rand);
      } else {
        setTimeout(() => {
          resolve(undefined);
        }, rand);
      }
    });
    return myPromise;
  };
} else {
  _fetch = fetch;
  thenJson = (resp: any) => {
    if (resp.status === 401) {
      delete localStorage.auth_token;
    }
    return resp.json();
  };
  catchLogout = (err: any) => {
    if (err.status === 401) {
      delete localStorage.auth_token;
    }
    throw err;
  };
}

class Client {
  baseUrl = window.location.href.startsWith("http://localhost:3000")
    ? "http://127.0.0.1:8000"
    : "";

  private url(path: string, authenticated = true) {
    if (authenticated) {
      return `${this.baseUrl}/api/${path}`;
    }
    return `${this.baseUrl}/${path}`;
  }

  private auth() {
    return localStorage.auth_token;
  }

  get(path: string, authenticated = true) {
    return _fetch(this.url(path, authenticated), {
      method: "GET",
      headers: {
        Authorization: this.auth(),
      },
    })
      .then(thenJson)
      .catch(catchLogout);
  }

  post(path: string, data: any, authenticated = true) {
    return _fetch(this.url(path, authenticated), {
      method: "POST",
      headers: {
        Authorization: this.auth(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(thenJson)
      .catch(catchLogout);
  }

  put(path: string, data: any) {
    return _fetch(this.url(path), {
      method: "PUT",
      headers: {
        Authorization: this.auth(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(thenJson)
      .catch(catchLogout);
  }

  delete(path: string) {
    return _fetch(this.url(path), {
      method: "DELETE",
      headers: {
        Authorization: this.auth(),
      },
    })
      .then(thenJson)
      .catch(catchLogout);
  }
}

class CrudService extends Client {
  resource = "";

  list() {
    console.log("LIST ", this.resource);
    return this.get(`${this.resource}/`);
  }

  add(data: any) {
    console.log("ADD NEW ", this.resource, data);
    return this.post(`${this.resource}/`, data);
  }

  update(id: any, data: any) {
    console.log("UPDATE ", this.resource, id, data);
    return this.put(`${this.resource}/${id}/`, data);
  }

  remove(id: any) {
    console.log("DELETE ", this.resource, id);
    return this.delete(`${this.resource}/${id}/`);
  }
}

class _GroupService extends CrudService {
  resource = "groups";
}

class _SurveyService extends CrudService {
  resource = "surveys";

  detail(id: string) {
    console.log("GET ", this.resource);
    const authenticated = false;
    return this.get(`survey_preview/${id}/`, authenticated);
  }
}

class _ResponseService extends Client {
  save(surveyId: any, responseDataJson: any) {
    console.log("SAVE response", surveyId, responseDataJson);
    const authenticated = false;
    return this.post(`response/${surveyId}/`, responseDataJson, authenticated);
  }

  getResults(surveyId: any) {
    return this.get(`response/${surveyId}/`);
  }
}

class _LoginService extends Client {
  login(username: string, password: string) {
    return this.post("login", { username, password });
  }
}

export const LoginService = new _LoginService();
export const GroupService = new _GroupService();
export const SurveyService = new _SurveyService();
export const ResponseService = new _ResponseService();
