import { PieChart } from "@mui/x-charts";
import { useEffect, useState } from "react";
import { ResponseService } from "../../../client";
import { Fab } from "@mui/material";
import styled from "styled-components";

const Container = styled.div`
  padding: 40px;
`;

const PiesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: space-around;
`;

const PieContainer = styled.div`
  flex: 45%;
  background: white;
  padding: 16px;
  border-radius: 10px;
`;

// const mock = {
//   survey_id: "06c40243-2cbc-4715-ae2e-886da75d0da3",
//   name: "Che sfida vorresti",
//   questions: {
//     Altro: [
//       {
//         answer: " Fave s ",
//         count: 1,
//       },
//       {
//         answer: "la mecca",
//         count: 1,
//       },
//       {
//         answer: "Sono Gregory",
//         count: 1,
//       },
//     ],
//     "Che ne pensi della qualit\u00e0 dei contenuti delle sfide attualmente disponibili su greenApes?":
//       [
//         {
//           answer: "5",
//           count: 1,
//         },
//       ],
//     "Da quando usi greenApes": [
//       {
//         answer: "2-5 mesi",
//         count: 1,
//       },
//       {
//         answer: "Da pochi giorni",
//         count: 1,
//       },
//       {
//         answer: "Pi\u00f9 di un anno",
//         count: 2,
//       },
//     ],
//     Design: [
//       {
//         answer: "10",
//         count: 1,
//       },
//       {
//         answer: "2",
//         count: 1,
//       },
//       {
//         answer: "5",
//         count: 1,
//       },
//       {
//         answer: "7",
//         count: 1,
//       },
//     ],
//     Genere: [
//       {
//         answer: "Femmina",
//         count: 1,
//       },
//       {
//         answer: "Maschio",
//         count: 3,
//       },
//     ],
//     "Hai qualche idea o pensiero aggiuntivo che vuoi condividere con il nostro team?  ":
//       [
//         {
//           answer: "4",
//           count: 1,
//         },
//         {
//           answer: "Ciao ciao ",
//           count: 1,
//         },
//         {
//           answer: "Dovrebbero riempirvi di soldi ",
//           count: 1,
//         },
//         {
//           answer: "si",
//           count: 1,
//         },
//       ],
//     "La tua et\u00e0": [
//       {
//         answer: "18-24",
//         count: 2,
//       },
//       {
//         answer: "35-44",
//         count: 2,
//       },
//     ],
//     "Post tematici": [
//       {
//         answer: "10",
//         count: 1,
//       },
//       {
//         answer: "3",
//         count: 2,
//       },
//       {
//         answer: "8",
//         count: 1,
//       },
//     ],
//     "Quale macro argomento ti piacerebbe approfondire?": [
//       {
//         answer: "Biodiversit\u00e0\nAcquisti sostenibili\nTurismo sostenibile",
//         count: 1,
//       },
//       {
//         answer: "Biodiversit\u00e0\nAlimentazione sostenibile",
//         count: 1,
//       },
//       {
//         answer:
//           "Mobilit\u00e0 sostenibile\nTurismo sostenibile\nCambiamento climantico",
//         count: 1,
//       },
//       {
//         answer:
//           "Mobilit\u00e0 sostenibile\nTurismo sostenibile\nEconomica circolare\nRisparmi enegetico",
//         count: 1,
//       },
//     ],
//     Quiz: [
//       {
//         answer: "1",
//         count: 1,
//       },
//       {
//         answer: "2",
//         count: 1,
//       },
//       {
//         answer: "3",
//         count: 1,
//       },
//       {
//         answer: "8",
//         count: 1,
//       },
//     ],
//     "Sfide con partner": [
//       {
//         answer: "2",
//         count: 1,
//       },
//       {
//         answer: "5",
//         count: 2,
//       },
//       {
//         answer: "8",
//         count: 1,
//       },
//     ],
//     "Sfide con premi": [
//       {
//         answer: "3",
//         count: 1,
//       },
//       {
//         answer: "4",
//         count: 2,
//       },
//       {
//         answer: "5",
//         count: 1,
//       },
//     ],
//     Video: [
//       {
//         answer: "1",
//         count: 3,
//       },
//       {
//         answer: "6",
//         count: 1,
//       },
//     ],
//   },
// };
const palettes = [
  undefined,
  ["orange", "gray", "slateblue", "lightcoral", "brown"],
];
function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const Results = ({ survey }: any) => {
  const [data, setRdata] = useState<any>(undefined);
  const [superset, setSuperset] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (data === undefined && mounted) {
      ResponseService.getResults(survey.id).then((resp: any) => {
        setRdata(resp);
      });
    }
    return () => {
      mounted = false;
    };
  }, []);

  if (!data) {
    // setRdata(mock);
    return <></>;
  }

  const toSeries = (answerList: any) => {
    return answerList.map((r: any, index: number) => {
      return {
        id: index,
        value: r.count,
        label: r.answer,
      };
    });
  };

  const randomPalette = () => {
    return palettes[randomIntFromInterval(0, palettes.length - 1)];
  };

  return (
    <>
      <Fab
        sx={{ position: "fixed", bottom: 20, right: 20 }}
        variant="extended"
        color="primary"
        onClick={() => {
          setSuperset(!superset);
        }}
      >
        Mostra {superset ? "Resoconto" : "Dashboard su Superset"}
      </Fab>
      {!superset && (
        <Container>
          <h2>{data.name}</h2>
          <PiesContainer>
            {Object.keys(data.questions).map((question: any) => (
              <PieContainer>
                <h3 style={{ margin: 0 }}>{question}</h3>
                <PieChart
                  colors={randomPalette()}
                  key={question}
                  title={question}
                  series={[
                    {
                      data: toSeries(data.questions[question]),
                      arcLabel: (item) => `${item.value}`,
                      innerRadius: 30,
                      outerRadius: 100,
                      paddingAngle: 5,
                      cornerRadius: 5,
                    },
                  ]}
                  height={400}
                />
              </PieContainer>
            ))}
          </PiesContainer>
        </Container>
      )}
      {superset && (
        <iframe
          width="100%"
          height={window.innerHeight - 84 - 70}
          seamless
          frameBorder={0}
          src={`https://greenapes-superset-dashboard-84d55716bab8.herokuapp.com/superset/dashboard/67/?standalone=true&name=${encodeURI(
            survey.title
          )}`}
        ></iframe>
      )}
    </>
  );
};
