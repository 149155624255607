import { FormLabel, TextField } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";
import { SurveyComponentProps } from "../component";

const Container = styled.div``;

function TextBuilder({ c, onFieldChange }: SurveyComponentProps) {
  const [form, _setForm] = useState({
    ...structuredClone(c),
  });

  const setForm = (form: any) => {
    onFieldChange(form);
    _setForm(form);
  };
  return (
    <Container>
      <FormLabel htmlFor="text">Testo markdown</FormLabel>
      <TextField
        fullWidth
        id="text"
        value={form.text}
        onChange={(e: any) => setForm({ ...form, text: e.target.value })}
      />
    </Container>
  );
}

export default TextBuilder;
