import { CheckRounded } from "@mui/icons-material";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  FormControl,
  ImageListItem,
  ImageListItemBar,
  ImageList,
  FormHelperText,
} from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { TextMd } from "../typografy/text_md";
import { isHtmlEmpty, uuidv4 } from "../../../../../utils/utils";

export const Title = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin-top: 0;
`;

export const FeedbackText = styled.p`
  border: 1px solid gray;
  margin: 0;
  padding: 15px;
  border-radius: 8px;
  margin-top: 15px;
  background: #f5f5f5;
`;

export const Description = styled.div`
  color: gray;
`;

export function QuestionChoicheComponent({
  c,
  supportImage,
  onResultChange,
}: any) {
  const [error, setError] = useState<boolean>(false);
  const [feedbackShow, setFeedbackShow] = useState<boolean>(false);

  const _onResultChange = (form: any) => {
    if (!isHtmlEmpty(c.feedback)) {
      setFeedbackShow(true);
    }

    onResultChange({
      question: c.title,
      responses: form.map((r: any) => {
        return {
          response: r,
          score: Number(
            c.options
              .filter((x: any) => x.value === r)
              .map((x: any) => x.score)[0]
          ),
        };
      }),
    });
  };

  return (
    <div style={{ border: error ? "2px solid red" : "", borderRadius: 5 }}>
      <Title>{c.title}</Title>
      {!isHtmlEmpty(c.description) && (
        <Description>
          <TextMd md={c.description} />
        </Description>
      )}
      {supportImage ? (
        <ImageChoice
          c={c}
          onResultChange={_onResultChange}
          onError={setError}
        />
      ) : !c.multiple ? (
        <RadioChoice
          c={c}
          onResultChange={_onResultChange}
          onError={setError}
        />
      ) : (
        <CheckboxChoices
          c={c}
          onResultChange={_onResultChange}
          onError={setError}
        />
      )}
      {feedbackShow && (
        <FeedbackText>
          <TextMd md={c.feedback} />
        </FeedbackText>
      )}
    </div>
  );
}

const RadioChoice = ({ c, onError, onResultChange }: any) => {
  const [error, setError] = useState<boolean>(c.required);
  const [result, setResult] = useState<any>([...structuredClone(c.options)]);

  useEffect(() => {
    setError(c.required);
  }, [c.required]);

  useEffect(() => {
    setResult(structuredClone(c.options));
  }, [c.options]);

  const onChange = (e: any) => {
    const { checked, value } = e.target;
    setError(!checked);
    onError(!checked);
    onResultChange([value]);
  };

  const uuid = uuidv4();

  return (
    <FormControl error={error}>
      <RadioGroup aria-labelledby={c.title} name={`${uuid}_${c.title}`}>
        {result.map((o: any, index: number) => (
          <FormControlLabel
            key={`${index}_${o.value}`}
            label={o.value}
            control={
              <Radio
                value={o.value}
                required={c.required}
                onChange={onChange}
              />
            }
          />
        ))}
      </RadioGroup>
      {c.required && (
        <FormHelperText style={{ color: error ? "red" : "gray" }}>
          Seleziona una voce
        </FormHelperText>
      )}
    </FormControl>
  );
};

const ImageChoice = ({ c, onError, onResultChange }: any) => {
  const [results, setResults] = useState<any>([...structuredClone(c.options)]);
  const [error, setError] = useState<boolean>(c.required);

  useEffect(() => {
    setResults(structuredClone(c.options));
  }, [c.options]);

  const onSelected = (image: string) => {
    const index = results.findIndex((r: any) => r.image === image);
    const _results = [...results];
    _results[index].checked = _results[index].checked ? false : true;
    let response = _results
      .filter((r: any) => r.checked)
      .map((r: any) => r.value);
    if (response.length > 1 && !c.multiple) {
      response = [_results[index].value];
      _results.forEach((r: any, i: number) => {
        if (index !== i) {
          r.checked = false;
        }
      });
    }
    setResults(_results);
    if (c.required) {
      const minReqError = c.multipleMin && response.length < c.multipleMin;
      const maxReqError = c.multipleMax && response.length > c.multipleMax;
      const err = response.length === 0 || minReqError || maxReqError;
      setError(err);
      onError(err);
    }
    onResultChange(response);
  };

  let cols = 2;
  if (c.options.length % 3 === 0) {
    cols = 3;
  }

  return (
    <>
      <ImageList sx={{}} cols={cols} rowHeight={250}>
        {results.map((o: any, index: number) => (
          <ImageListItem
            key={`${index}_${o.image}`}
            style={{ position: "relative" }}
          >
            <img
              onClick={() => onSelected(o.image)}
              srcSet={`${o.image}`}
              src={`${o.image}`}
              alt={o.value}
              style={{
                maxHeight: 180,
                cursor: "pointer",
                position: "relative",
              }}
              loading="lazy"
            />
            {o.checked && (
              <CheckRounded color="success" className="image-selected" />
            )}
            {o.value && (
              <ImageListItemBar
                title={o.value}
                position="below"
                style={{
                  background: o.checked ? "#4CAF50" : "transparent",
                  color: o.checked ? "white" : "black",
                  borderBottomLeftRadius: 5,
                  borderBottomRightRadius: 5,
                  padding: 5,
                }}
              />
            )}
          </ImageListItem>
        ))}
      </ImageList>
      {c.required && (
        <FormHelperText style={{ color: error ? "red" : "gray" }}>
          <input
            required
            style={{
              width: 1,
              border: 0,
              outline: 0,
              color: "transparent",
              cursor: "default",
            }}
            value={error ? "" : "filled"}
            onChange={() => {}}
            name={`${uuidv4()}_image_input_${c.title}`}
          />
          Seleziona almeno{" "}
          {!c.multipleMin || Number(c.multipleMin) === 1
            ? "un immagine"
            : `${c.multipleMin} immagini`}{" "}
          {c.multipleMax &&
            c.multipleMax > 1 &&
            `, massimo ${c.multipleMax} immagini`}
        </FormHelperText>
      )}
    </>
  );
};

const CheckboxChoices = ({ c, onError, onResultChange }: any) => {
  const [results, setResults] = useState<any>(structuredClone(c.options));
  const [error, setError] = useState<boolean>(c.required);

  useEffect(() => {
    setResults(structuredClone(c.options));
  }, [c.options]);

  const onChange = (e: any) => {
    const { checked, value } = e.target;
    const index = results.findIndex((r: any) => r.value === value);
    const _results = [...results];
    _results[index].checked = checked;
    const response = _results
      .filter((r: any) => r.checked)
      .map((r: any) => r.value);
    setResults(_results);
    if (c.required) {
      const minReqError = c.multipleMin && response.length < c.multipleMin;
      const maxReqError = c.multipleMax && response.length > c.multipleMax;
      const err = response.length === 0 || minReqError || maxReqError;
      setError(err);
      onError(err);
    }
    onResultChange(response);
  };

  return (
    <>
      <FormControl
        error={error}
        required
        component="fieldset"
        variant="standard"
      >
        <FormGroup>
          {results.map((o: any, index: number) => (
            <FormControlLabel
              key={`${index}_${o.value}`}
              control={
                <Checkbox
                  checked={o.checked}
                  onChange={onChange}
                  name={`${uuidv4()}${o.value}`}
                  value={o.value}
                />
              }
              label={o.value}
            />
          ))}
        </FormGroup>
        {c.required && (
          <FormHelperText>
            <input
              required
              style={{
                width: 1,
                border: 0,
                outline: 0,
                color: "transparent",
                cursor: "default",
              }}
              value={error ? "" : "filled"}
              onChange={() => {}}
              name={`${uuidv4()}_input_${c.title}`}
            />
            Seleziona almeno{" "}
            {!c.multipleMin || Number(c.multipleMin) === 1
              ? "una voce"
              : `${c.multipleMin} voci`}{" "}
            {c.multipleMax &&
              c.multipleMax > 1 &&
              `, massimo ${c.multipleMax} voci`}
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
};
