import React, { useState } from "react";
import { FormResponseType } from "./react-components/survey/editor/components/component";
import { GroupService, LoginService, SurveyService } from "./client";
import { Button, Card, FormControl, FormLabel, TextField } from "@mui/material";
import { SurveyType, GroupType, SurveyResponseType } from "./types";

export type State = {
  surveys: SurveyType[];
  groups: GroupType[];
  username: any;
  updateSurvey: (
    formData: any,
    idSurvey?: any,
    indexPage?: number,
    indexComponent?: number,
    insert?: boolean,
    feedbackPage?: any
  ) => any;
  saveSurveys: () => void;
  addGroup: (title: string) => void;
  removeGroup: (id: any) => void;
  moveSurveyInGroup: (groupId: any, idSurvey: any) => void;
};

export const StateContext = React.createContext<State | null>(null);

const StateProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [surveys, setSurveys] = React.useState<SurveyType[]>([]);
  const [groups, setgroups] = React.useState<GroupType[]>([]);
  const [token, setToken] = React.useState<string | null>(null);
  const [username, setUsername] = React.useState<any>(undefined);
  const [loadingGroups, setLoadingGroups] = React.useState<any>(undefined);
  const [loadingSurveys, setLoadingSurveys] = React.useState<any>(undefined);

  if (!localStorage.auth_token) {
    return (
      <LoginPage
        onLoginSuccess={(resp: any) => {
          localStorage.auth_token = resp.token;
          localStorage.username = resp.username;
          setToken(resp.token);
          setUsername(resp.username);
        }}
      />
    );
  } else if (!token && localStorage.auth_token) {
    setToken(localStorage.auth_token);
    setUsername(localStorage.username);
  }

  if (loadingSurveys === undefined) {
    setLoadingSurveys(true);
    SurveyService.list()
      .then((resp: any) => {
        setSurveys(resp);
        setLoadingSurveys(false);
      })
      .catch((e: any) => {
        console.log(e);
        setLoadingSurveys(false);
      });
  }

  if (loadingGroups === undefined) {
    setLoadingGroups(true);
    GroupService.list()
      .then((resp: any) => {
        setgroups(resp);
        setLoadingGroups(false);
      })
      .catch((e: any) => {
        console.log(e);
        setLoadingGroups(false);
      });
  }

  const saveSurveys = (updated?: any) => {
    localStorage.surveys = JSON.stringify(updated || surveys);
  };

  const updateSurvey = async (
    formData: any,
    idSurvey?: any,
    indexPage?: number,
    indexComponent?: number,
    insert: boolean = false,
    feedbackPage: any = undefined
  ) => {
    if (!idSurvey) {
      return await SurveyService.add(formData).then(({ id }) => {
        formData.id = id;
        const res = surveys.concat([formData]);
        setSurveys(res);
        saveSurveys(res);
        return res;
      });
    } else {
      const survey: any = surveys.find((s: any) => s.id === idSurvey);
      if (survey) {
        if (indexPage !== undefined && indexComponent !== undefined) {
          const changeSpace = feedbackPage
            ? survey[feedbackPage]
            : survey.pages[indexPage];
          if (insert) {
            changeSpace.components.splice(indexComponent, 0, formData);
          } else if (formData.delete) {
            changeSpace.components.splice(indexComponent, 1);
          } else {
            changeSpace.components[indexComponent] = formData;
          }
        } else if (indexPage !== undefined) {
          if (insert) {
            survey.pages.splice(indexPage, 0, formData);
          } else if (formData.delete) {
            survey.pages.splice(indexPage, 1);
          } else {
            if (feedbackPage) {
              survey[feedbackPage].title = formData.title;
              survey[feedbackPage].description = formData.description;
              survey[feedbackPage].image = formData.image;
              survey[feedbackPage].color = formData.color;
            } else {
              survey.pages[indexPage].title = formData.title;
              survey.pages[indexPage].description = formData.description;
              survey.pages[indexPage].image = formData.image;
              survey.pages[indexPage].color = formData.color;
            }
          }
        } else {
          if (formData.delete) {
            return await SurveyService.remove(survey.id).then(() => {
              const res = surveys.filter((s: any) => s.id !== survey.id);
              setSurveys(res);
              saveSurveys(res);
              return res;
            });
          } else {
            survey.title = formData.title;
            survey.description = formData.description;
            survey.isQuiz = formData.isQuiz;
            survey.published = formData.published;
            survey.minRateToSuccess = formData.minRateToSuccess;
            survey.saveUserResponseToDatabase =
              formData.saveUserResponseToDatabase;
            survey.backgroundColor = formData.backgroundColor;
            survey.backgroundImage = formData.backgroundImage;
            survey.backgroundImageSize = formData.backgroundImageSize;
            survey.backgroundImageMobile = formData.backgroundImageMobile;
            survey.backgroundImageSizeMobile =
              formData.backgroundImageSizeMobile;
            survey.surveyBackgroundColor = formData.surveyBackgroundColor;
            survey.componentBackgroundColor = formData.componentBackgroundColor;
            survey.surveyButtonsColor = formData.surveyButtonsColor;
            survey.surveyBarColor1 = formData.surveyBarColor1;
            survey.surveyBarColor2 = formData.surveyBarColor2;
          }
        }
      }
      const res = surveys.map((s: any) => (s.id === idSurvey ? survey : s));
      return await SurveyService.update(survey.id, survey).then(() => {
        setSurveys(res);
        saveSurveys(res);
        return res;
      });
    }
  };

  const addGroup = (title: string) => {
    GroupService.add({ title }).then(({ id }) => {
      const newGroup = {
        id,
        title,
      };
      const _groups = [...groups, newGroup];
      setgroups(_groups);
      localStorage.groups = JSON.stringify(_groups);
    });
  };

  const removeGroup = (id: any) => {
    GroupService.remove(id).then(() => {
      const _surveys = [...surveys];
      _surveys.forEach((s: any) => {
        if (s.groupId === id) {
          s.groupId = undefined;
        }
      });
      setSurveys(_surveys);
      saveSurveys(_surveys);

      const _groups = groups.filter((g: GroupType) => g.id !== id);
      setgroups(_groups);
      localStorage.groups = JSON.stringify(_groups);
    });
  };

  const moveSurveyInGroup = (groupId: any, idSurvey: any) => {
    const survey = surveys.find((s: any) => s.id === idSurvey);
    if (survey) {
      const _surveys = [...surveys];
      _surveys.forEach((s: any) => {
        if (s.id === survey.id) {
          s.groupId = groupId;
        }
      });
      SurveyService.update(survey.id, { ...survey, groupId }).then(() => {
        setSurveys(_surveys);
        saveSurveys(_surveys);
      });
    }
  };

  if (loadingSurveys !== undefined && loadingGroups) {
    return <h1>Loading ...</h1>;
  }

  return (
    <StateContext.Provider
      value={{
        username,
        surveys,
        groups,
        addGroup,
        removeGroup,
        updateSurvey,
        saveSurveys,
        moveSurveyInGroup,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const resetFormResponse = () => {
  delete localStorage.currentFormResponse;
};

export const completeSurvey = (survey: SurveyType) => {
  let success;
  let form: SurveyResponseType;
  if (!localStorage.currentFormResponse) {
    initializeFormResponse(survey.id, survey, "");
    form = JSON.parse(localStorage.currentFormResponse);
  }
  form = JSON.parse(localStorage.currentFormResponse);
  if (survey.isQuiz) {
    const score = form.responses
      .map((s: any) =>
        s.responses.reduce((a: any, v: any) => a + Number(v.score), 0)
      )
      .reduce((a: any, v: any) => a + v, 0);
    form.result.minRateToSuccess = survey.minRateToSuccess || 0;
    if (score >= form.result.minRateToSuccess) {
      form.result.success = true;
      form.result.score = score;
      success = true;
    } else {
      form.result.success = false;
      form.result.score = score;
      success = false;
    }
  } else {
    form.result.success = true;
    success = true;
  }
  localStorage.currentFormResponse = JSON.stringify(form);
  return [form, success];
};

export const initializeFormResponse = (
  survey_quiz_id: any,
  survey: SurveyType,
  data: string
) => {
  const out: SurveyResponseType = {
    survey_quiz_id,
    callback_params_b64: data,
    saveUserResponseToDatabase: survey.saveUserResponseToDatabase,
    isQuiz: survey.isQuiz,
    result: {
      success: true,
    },
    responses: [],
  };
  if (survey.isQuiz) {
    out.result = {
      success: false,
      score: 0,
      minRateToSuccess: survey.minRateToSuccess,
    };
  }
  localStorage.currentFormResponse = JSON.stringify(out);
};

export const updateFormResponse = (form: FormResponseType) => {
  if (!localStorage.currentFormResponse) {
    return;
  }
  let surveyForm = JSON.parse(localStorage.currentFormResponse);
  const index = surveyForm.responses.findIndex(
    (r: FormResponseType) => r.questionId === form.questionId
  );
  if (index !== -1) {
    surveyForm.responses[index] = form;
  } else {
    surveyForm.responses.push(form);
  }
  localStorage.currentFormResponse = JSON.stringify(surveyForm);
  console.log(JSON.stringify(surveyForm, null, 2));
};

export const getFormResponse = () => {
  return JSON.parse(localStorage.currentFormResponse);
};

const LoginPage = ({ onLoginSuccess }: any) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const login = () => {
    LoginService.login(username, password)
      .then((resp) => {
        onLoginSuccess(resp);
      })
      .catch(() => {
        setError(true);
      });
  };

  return (
    <div className="login-container">
      <Card
        variant="outlined"
        sx={{
          p: 2,
          width: 400,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        <FormControl>
          <FormLabel>Username</FormLabel>
          <TextField
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <FormLabel>Password</FormLabel>
          <TextField
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && (
            <p style={{ color: "red" }}>
              Username o password non sono corretti
            </p>
          )}
          <Button onClick={login}>Login</Button>
        </FormControl>
      </Card>
    </div>
  );
};

export default StateProvider;
