import "./App.css";
import SurveyList from "./react-components/survey/list";
import SurveyEditor from "./react-components/survey/editor/editor";
import StateContext, {
  initializeFormResponse,
  resetFormResponse,
} from "./state";
import { BrowserRouter, Route, Routes } from "react-router";
import {
  NoContent,
  SurveyProduction,
} from "./react-components/survey/editor/PreviewView";
import { Layout } from "./react-components/survey/layout";
import { SurveyService } from "./client";
import { useState } from "react";

let beforeunloadListener: any;

function SurveyWrapper({ survey, children }: any) {
  const searchParams = new URLSearchParams(document.location.search);
  if (survey) {
    initializeFormResponse(survey.id, survey, searchParams.get("data") || "");
  }
  if (!beforeunloadListener) {
    beforeunloadListener = window.addEventListener("beforeunload", (event) => {
      resetFormResponse();
    });
  }
  return children;
}

function App() {
  const [survey, setSurvey] = useState<any>(null);
  const [detailLoading, setDetailLoading] = useState<any>(undefined);

  if (window.location.pathname.startsWith("/su/")) {
    const surveyId = window.location.pathname
      .split("/su/")[1]
      .split("/")[0]
      .split("?")[0];

    if (detailLoading === undefined) {
      setDetailLoading(true);
      SurveyService.detail(surveyId)
        .then((resp: any) => {
          setSurvey(resp);
          setDetailLoading(false);
        })
        .catch((err: any) => {
          setDetailLoading(false);
        });
    }
    if (detailLoading === undefined && !survey) {
      return <NoContent message={"Caricamento..."} />;
    }
    if (detailLoading === false && !survey) {
      return <NoContent message={"Survey non trovato"} />;
    }

    return (
      <SurveyWrapper survey={survey}>
        <SurveyProduction survey={survey} />
      </SurveyWrapper>
    );
  }

  return (
    <StateContext>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<SurveyList />} />
            <Route path="edit/survey/:id" element={<SurveyEditor />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </StateContext>
  );
}

export default App;
