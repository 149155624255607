import { useState } from "react";
import { TextValidator } from "react-material-ui-form-validator";
import styled from "styled-components";
import { Description, FeedbackText, Title } from "./question_multiple";
import { TextMd } from "../typografy/text_md";
import { isHtmlEmpty } from "../../../../../utils/utils";

const Container = styled.div``;

function QuestionTextComponent({ c, type, onResultChange }: any) {
  const [value, _setValue] = useState(null);

  const validators = [];
  const errorMessages = [];

  if (c.required) {
    validators.push("required");
    errorMessages.push("Campo obbligatorio");
  }

  let help = `Massimo ${c.maxLength} caratteri`;
  if (type === "number") {
    help = `Inserisci un numero tra ${c.min} e ${c.max}`;
  }
  if (type === "email") {
    help = "Inserisci una email valida";
    validators.push("isEmail");
    errorMessages.push("Email non valida");
  }
  let inputProps;
  if (type === "text" || !type) {
    inputProps = { maxLength: c.maxLength };
  } else if (type === "number") {
    inputProps = {
      min: c.min,
      max: c.max,
    };
  }

  const onChange = (e: any) => {
    const value = type === "number" ? Number(e.target.value) : e.target.value;
    _setValue(e.target.value);
    onResultChange({
      question: c.title,
      responses: [{ response: value, score: 0 }],
    });
  };

  return (
    <Container>
      <Title>
        {c.title}
        {c.required ? " *" : ""}
      </Title>
      {!isHtmlEmpty(c.description) && (
        <Description>
          <TextMd md={c.description} />
        </Description>
      )}
      <TextValidator
        type={type}
        helperText={help}
        inputProps={inputProps}
        maxRows={10}
        fullWidth
        multiline={type === "text" || !type}
        name={c.title}
        required={c.required}
        value={value}
        onChange={onChange}
        validators={validators}
        errorMessages={errorMessages}
      />
      {value && !isHtmlEmpty(c.feedback) && (
        <FeedbackText>
          <TextMd md={c.feedback} />
        </FeedbackText>
      )}
    </Container>
  );
}

export default QuestionTextComponent;
