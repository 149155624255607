import styled from "styled-components";
import HeaderComponent from "../../viewer/components/typografy/header";
import TextComponent, {
  TextMd,
} from "../../viewer/components/typografy/text_md";
import { Component, FormResponseType } from "../components/component";
import { QuestionChoicheBuilder } from "../components/questions/question_multiple";
import HeaderBuilder from "../components/typografy/header";
import TextBuilder from "../components/typografy/text_md";
import { getFormResponse, updateFormResponse } from "../../../../state";
import QuestionTextBuilder from "../components/questions/question_text";
import QuestionTextComponent from "../../viewer/components/questions/question_text";
import MediaBuilder from "../components/typografy/media";
import MediaComponent from "../../viewer/components/typografy/media";
import { QuestionChoicheComponent } from "../../viewer/components/questions/question_multiple";
import { Button, Chip } from "@mui/material";
import { isHtmlEmpty } from "../../../../utils/utils";

const Title = styled.p`
  font-size: 40px;
  font-weight: bold;
  margin: 0;
`;

const Description = styled.div`
  font-size: 18px;
  color: gray;
`;

const PageContainer = styled.div``;

const Page = styled.div`
  padding-top: 15px;
  border-radius: 8px;
`;

const PageTextheaeder = styled.div`
  border-radius: 3px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin: -15px;
  padding: 15px;
  margin-bottom: 5px;
`;
const PageText = styled.p`
  color: white;
  font-weight: bold;
  margin: 0;
`;

const PageHeader = styled.div`
  background-size: cover;
  background-position: center;
  border-radius: 3px;
`;

const DropZone = styled.div`
  padding: 20px;
  border: 2px dotted green;
  border-radius: 8px;
  margin: 20px 0;
  background-color: #a2e8a2;
`;

function SurveyPage(props: any) {
  const { page, selectedWidget } = props;

  const supported = props.feedbackPage
    ? props.supportedList
      ? props.supportedList.indexOf(selectedWidget?.type) !== -1
      : true
    : true;

  const pageTitle = props.feedbackPage
    ? props.feedbackPage === "success_page"
      ? "PAGINA DI SUCCESSO"
      : "PAGINA DI ERRORE"
    : `PAGINA ${props.indexPage + 1} `;

  const indexPage = props.indexPage + 1;

  const onResponseChange = (form: FormResponseType, indexComponent: number) => {
    const questionId = `Page_${indexPage}__Question_${indexComponent}`;
    form.questionId = questionId;
    console.log("questionId", questionId);
    updateFormResponse(form);
  };

  let finish;
  if (
    props.feedbackPage &&
    !props.editMode &&
    localStorage.currentFormResponse
  ) {
    try {
      finish = JSON.parse(localStorage.currentFormResponse);
    } catch {}
  }
  let retry =
    props.feedbackPage === "error_page" &&
    !props.editMode &&
    localStorage.currentFormResponse;

  const renderDropZone = (after: boolean, indexComponent?: number) => {
    return (
      <DropZone
        onDrop={(e) => {
          console.log(
            indexComponent !== undefined
              ? indexComponent + (after ? 1 : 0)
              : false
          );
          props.updateSurvey(
            selectedWidget,
            props.idSurvey,
            indexComponent === undefined && after
              ? props.indexPage + 1
              : props.indexPage,
            indexComponent !== undefined
              ? indexComponent + (after ? 1 : 0)
              : undefined,
            true,
            props.feedbackPage
          );
        }}
        onDragOver={(e: any) => {
          e.target.style.background = "#00e600";
        }}
        onDragLeave={(e: any) => {
          e.target.style.background = "#a2e8a2";
        }}
      >
        Posiziona qui
      </DropZone>
    );
  };

  const renderComponent = (c: any, indexComponent: number) => {
    let editorComponent, previewComponent;
    let noWrapPreview = false;

    switch (c.type) {
      case "header":
        editorComponent = (params: any) => <HeaderBuilder {...params} />;
        previewComponent = (params: any) => <HeaderComponent {...params} />;
        break;
      case "text_md":
        editorComponent = (params: any) => <TextBuilder {...params} />;
        previewComponent = (params: any) => <TextComponent {...params} />;
        break;
      case "question_choice":
        editorComponent = (params: any) => (
          <QuestionChoicheBuilder {...params} />
        );
        previewComponent = (params: any) => (
          <QuestionChoicheComponent {...params} />
        );
        break;
      case "question_imagegrid":
        editorComponent = (params: any) => (
          <QuestionChoicheBuilder supportImage {...params} />
        );
        previewComponent = (params: any) => (
          <QuestionChoicheComponent supportImage {...params} />
        );
        break;
      case "question_text":
        editorComponent = (params: any) => <QuestionTextBuilder {...params} />;
        previewComponent = (params: any) => (
          <QuestionTextComponent {...params} />
        );
        break;
      case "question_number":
        editorComponent = (params: any) => (
          <QuestionTextBuilder type="number" {...params} />
        );
        previewComponent = (params: any) => (
          <QuestionTextComponent type="number" {...params} />
        );
        break;
      case "question_email":
        editorComponent = (params: any) => (
          <QuestionTextBuilder type="email" {...params} />
        );
        previewComponent = (params: any) => (
          <QuestionTextComponent type="email" {...params} />
        );
        break;
      case "media_image":
        editorComponent = (params: any) => (
          <MediaBuilder variant="image" {...params} />
        );
        previewComponent = (params: any) => (
          <MediaComponent variant="image" {...params} />
        );
        break;
      case "media_video":
        editorComponent = (params: any) => (
          <MediaBuilder variant="video" {...params} />
        );
        previewComponent = (params: any) => (
          <MediaComponent variant="video" {...params} />
        );
        break;
      case "media_iframe":
        editorComponent = (params: any) => (
          <MediaBuilder variant="iframe" {...params} />
        );
        previewComponent = (params: any) => (
          <MediaComponent variant="iframe" {...params} />
        );
        break;
      case "divider":
        previewComponent = (params: any) => <div style={{ height: 20 }} />;
        noWrapPreview = true;
        break;
      case "divider-line":
        previewComponent = (params: any) => (
          <div style={{ height: 20, borderBottom: "1px solid black" }} />
        );
        noWrapPreview = true;
        break;
      default:
        return <></>;
    }

    return (
      <span key={indexComponent}>
        <Component
          noWrapPreview={noWrapPreview}
          editMode={props.editMode}
          {...props}
          c={c}
          componentBackgroundColor={props.survey.componentBackgroundColor}
          indexComponent={indexComponent}
          renderPreview={previewComponent}
          renderEditor={editorComponent}
          feedbackPage={props.feedbackPage}
          onResponseChange={(r: any) => onResponseChange(r, indexComponent)}
          updateSurvey={props.updateSurvey}
          page={undefined}
        />
        {selectedWidget && supported && renderDropZone(true, indexComponent)}
      </span>
    );
  };

  const pageHeaderStyle = (page: any) => {
    // if (props.isMobile) {
    //   return {
    //     padding: "20px",
    //     backgroundColor: page.backgroundColor || "white",
    //   };
    // }
    return {
      backgroundImage: page.image ? `url(${page.image})` : "",
      padding: page.image ? "20px" : "20px 0s",
    };
  };
  return (
    <PageContainer
      id={`PAGE_ID_INDEX_${props.indexPage}`}
      onDragOver={(event) => {
        event.preventDefault();
      }}
      onDragLeave={(event) => {
        event.preventDefault();
      }}
    >
      {selectedWidget?.type === "page" && props.indexPage === 0 && (
        <>{renderDropZone(false)}</>
      )}
      <Page
        className="page-responsive"
        style={
          props.editMode
            ? {
                border:
                  props.feedbackPage === "success_page"
                    ? "3px solid #2e7d32"
                    : props.feedbackPage === "error_page"
                    ? "3px solid #d3302f"
                    : "3px solid #4c4c4c",
                marginBottom: 40,
                overflow: "hidden",
                height: selectedWidget?.type === "page" ? 60 : "auto",
              }
            : {}
        }
      >
        {props.editMode && (
          <PageTextheaeder
            style={{
              backgroundColor:
                props.feedbackPage === "success_page"
                  ? "#2e7d32"
                  : props.feedbackPage === "error_page"
                  ? "#d3302f"
                  : "#4c4c4c",
            }}
          >
            <PageText>{pageTitle}</PageText>
            {selectedWidget?.type === "page" && !props.feedbackPage && (
              <PageText>{page.title}</PageText>
            )}
          </PageTextheaeder>
        )}
        <Component
          noWrapPreview
          disableDelete={props.feedbackPage !== undefined}
          editMode={props.editMode}
          {...props}
          c={{
            title: page.title,
            description: page.description,
            image: page.image,
            color: page.color,
          }}
          componentBackgroundColor={props.survey.componentBackgroundColor}
          page={page}
          renderPreview={(params: any) =>
            page.title || page.description ? (
              <PageHeader style={pageHeaderStyle(page)}>
                {!isHtmlEmpty(page.title) && (
                  <Title
                    style={{
                      color: page.color ? page.color : "black",
                    }}
                  >
                    {page.title}
                  </Title>
                )}
                {!isHtmlEmpty(page.description) && (
                  <Description
                    style={{
                      color: page.color ? page.color : "gray",
                    }}
                  >
                    <TextMd md={page.description} />
                  </Description>
                )}
              </PageHeader>
            ) : (
              <></>
            )
          }
          renderEditor={(params: any) => {
            console.log(params.c);
            return <HeaderBuilder enableColor {...params} />;
          }}
          updateSurvey={props.updateSurvey}
        />
        {selectedWidget && supported && renderDropZone(false, 0)}
        {page.components.map((c: any, indexComponent: number) => {
          return renderComponent(c, indexComponent);
        })}
        {props.feedbackPage && <FormResults isQuiz={props.isQuiz} />}
        {retry && (
          <>
            <br />
            <Button
              fullWidth
              variant="contained"
              size="large"
              color="success"
              onClick={props.retrySurvey}
            >
              Ritenta
            </Button>
          </>
        )}
      </Page>
      {selectedWidget?.type === "page" &&
        !props.feedbackPage &&
        renderDropZone(true)}
    </PageContainer>
  );
}

const FormResults = ({ isQuiz }: any) => {
  const formResponseForm = getFormResponse();
  return (
    <div>
      {isQuiz && (
        <Chip
          label={`Hai totalizzato ${formResponseForm.result.score}/${formResponseForm.result.minRateToSuccess} punti`}
          sx={{
            fontSize: 22,
            padding: 3,
            width: "100%",
            textOverflow: "clip",
            whiteSpace: "break-spaces",
          }}
        />
      )}
    </div>
  );
};

export default SurveyPage;
