export const isHtmlEmpty = (html: string): boolean => {
  if (!html) {
    return true;
  }
  const el = document.createElement("div");
  el.innerHTML = html;
  const text = el.innerText;
  const isEmpty = !text || text.trim() === "";
  return isEmpty;
};

export const uuidv4 = () => {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
    (
      +c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
    ).toString(16)
  );
};
