import { Button, Chip, LinearProgress } from "@mui/material";
import SurveyPage from "./pages/page";
import { SurveyContainer, SurveyHeader } from "./editor";
import { ComponentNavBar } from "./EditorView";
import { useContext, useState } from "react";
import { useParams } from "react-router";
import {
  completeSurvey,
  initializeFormResponse,
  State,
  StateContext,
} from "../../../state";
import { ValidatorForm } from "react-material-ui-form-validator";
import { ResponseService } from "../../../client";
import styled from "styled-components";

enum StatusEnum {
  "doing",
  "success",
  "error",
}

const chipFloating: any = {
  position: "absolute",
  right: 15,
  top: 15,
  background: "white",
};

function Loading() {
  return (
    <center>
      <h3>Caricamento in corso...</h3>
    </center>
  );
}

const retrySurvey = () => {
  window.location.reload();
};

const SurveyButtonSheet = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  gap: 25px;
`;

export const Survey = ({ survey, screenSize }: any) => {
  const [currentPage, _setCurrentPage] = useState<number>(0);
  const [status, setStatus] = useState<StatusEnum>(StatusEnum.doing);
  const params = useParams();
  const { surveys, updateSurvey } = useContext(StateContext) as State;

  if (surveys.length === 0) {
    return <Loading />;
  }

  if (!survey) {
    survey = surveys.find((s: any) => String(s.id) === params.id);
  }

  const setCurrentPage = (page: number) => {
    window.scrollTo(0, 0);
    _setCurrentPage(page);
  };

  const validateAndComplete = () => {
    if (currentPage < survey.pages.length - 1) {
      setCurrentPage(currentPage + 1);
    } else {
      const [form, success] = completeSurvey(survey);
      if (success) {
        setStatus(StatusEnum.success);
      } else {
        setStatus(StatusEnum.error);
      }
    }
  };

  const style: any = {};
  if (survey.surveyBackgroundColor) {
    style.marginTop = 40;
    style.borderRadius = 8;
    style.boxShadow = "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)";
  }
  let isMobile = false;
  if (
    window.matchMedia("(max-width: 756px)").matches ||
    screenSize === "mobile"
  ) {
    style.margin = 0;
    style.borderRadius = 0;
    if (survey.backgroundImage) {
      style.backgroundColor = "transparent";
      isMobile = true;
    }
  }

  if (survey.surveyBackgroundColor) {
    style.backgroundColor = survey.surveyBackgroundColor;
  }

  const checkKeyDown = (e: any) => {
    if (e.key === "Enter") e.preventDefault();
  };

  const buttonColor: any = {};
  if (survey.surveyButtonsColor) {
    buttonColor.backgroundColor = survey.surveyButtonsColor;
  }
  const barColor: any = {};
  if (survey.surveyBarColor) {
    barColor.backgroundColor = survey.surveyBarColor1;
    const bar: any = document.querySelector("#linearprogress > span");
    if (bar) {
      bar.style.backgroundColor = survey.surveyBarColor2;
    }
  }

  return (
    <SurveyContainer style={style}>
      {/* <SurveyHeader
        survey={survey}
        currentPage={currentPage}
        isMobile={isMobile}
      /> */}
      {currentPage !== undefined && survey.pages.length > 1 && (
        <Chip
          label={`Pagina ${currentPage + 1} / ${survey.pages.length}`}
          variant="outlined"
          style={{
            ...chipFloating,
            boxShadow: isMobile
              ? "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"
              : "",
          }}
        />
      )}
      {status === StatusEnum.doing && (
        <>
          {survey.pages
            .map((c: any, indexPage: number) => {
              return { ...c, indexPage };
            })
            .filter((_c: any, index: number) => index === currentPage)
            .map((c: any) => {
              return (
                <ValidatorForm
                  onSubmit={validateAndComplete}
                  onKeyDown={checkKeyDown}
                  onError={(errors) => console.log("FORM ERRORS", errors)}
                >
                  <SurveyPage
                    key={c.indexPage}
                    page={c}
                    indexPage={c.indexPage}
                    idSurvey={survey.id}
                    survey={survey}
                    isMobile={isMobile}
                  />
                  <div
                    style={{
                      padding: isMobile ? 20 : 0,
                      margin: isMobile ? -20 : 0,
                    }}
                  >
                    {survey.pages.length > 1 && (
                      <LinearProgress
                        id="linearprogress"
                        sx={{ height: 10 }}
                        style={{ margin: 20, ...barColor }}
                        variant="determinate"
                        value={(currentPage / (survey.pages.length - 1)) * 100}
                      />
                    )}
                    <SurveyButtonSheet>
                      {currentPage > 0 && (
                        <Button
                          size="large"
                          style={{ color: "black" }}
                          onClick={() => setCurrentPage(currentPage - 1)}
                        >
                          Indietro
                        </Button>
                      )}

                      {currentPage < survey.pages.length - 1 && (
                        <Button
                          variant="contained"
                          size="large"
                          color="success"
                          type="submit"
                          style={buttonColor}
                        >
                          Avanti
                        </Button>
                      )}
                      {currentPage === survey.pages.length - 1 && (
                        <Button
                          fullWidth={isMobile && survey.pages.length === 1}
                          type="submit"
                          variant="contained"
                          size="large"
                          color="success"
                          style={buttonColor}
                        >
                          Conferma e invia
                        </Button>
                      )}
                    </SurveyButtonSheet>
                  </div>
                </ValidatorForm>
              );
            })}
        </>
      )}
      {status === StatusEnum.success && (
        <SurveyPage
          feedbackPage="success_page"
          isQuiz={survey.isQuiz}
          page={survey.success_page}
          indexPage={survey.pages.length}
          idSurvey={survey.id}
          survey={survey}
          updateSurvey={updateSurvey}
          isMobile={isMobile}
        />
      )}
      {status === StatusEnum.error && (
        <SurveyPage
          feedbackPage="error_page"
          isQuiz={survey.isQuiz}
          page={survey.error_page}
          indexPage={survey.pages.length}
          idSurvey={survey.id}
          survey={survey}
          updateSurvey={updateSurvey}
          retrySurvey={retrySurvey}
          isMobile={isMobile}
        />
      )}
    </SurveyContainer>
  );
};

const PreviewView = ({ survey }: any) => {
  const [screenSize, setScreenSize] = useState<string>("desktop");

  let style2: any = { margin: "auto", width: "100%" };
  let editorStyle: any = {};
  const isMobile = screenSize === "mobile";
  if (isMobile) {
    style2.width = 450;
    editorStyle.backgroundColor = "black";
  }

  const style: any = {
    display: "flex",
  };
  if (survey.backgroundColor) {
    style.backgroundColor = survey.backgroundColor;
  }

  if (survey.backgroundColor) {
    style.backgroundColor = survey.backgroundColor;
  }

  const img =
    isMobile && survey.backgroundImageMobile
      ? survey.backgroundImageMobile || survey.backgroundImage
      : survey.backgroundImage || survey.backgroundImageMobile;
  const size =
    isMobile && survey.backgroundImageSizeMobile
      ? survey.backgroundImageSizeMobile
      : survey.backgroundImageSize;

  if (img) {
    style.backgroundImage = `url('${img}')`;
    style.backgroundSize =
      size === "cover" || size === "coverfixed" ? "cover" : "";
    if (size === "coverfixed") {
      style.backgroundAttachment = "fixed";
    }
    if (size === "pattern300") {
      style.backgroundSize = "300px";
    }
    if (size === "pattern100") {
      style.backgroundSize = "100px";
    }
    if (size === "pattern100%") {
      style.backgroundSize = "100%";
    }
  }

  initializeFormResponse(survey.id, survey, "");

  return (
    <div className="editor" style={editorStyle}>
      <ComponentNavBar
        exitOnly
        survey={survey}
        showPreviews
        onScreenSizeChange={(size: string) => setScreenSize(size)}
      />
      <div style={style2}>
        <div className="survey-board tab-scroll">
          <div style={style}>
            <Survey survey={survey} screenSize={screenSize} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const SurveyProduction = ({ survey }: any) => {
  const [currentPage, _setCurrentPage] = useState<number>(0);
  const [status, setStatus] = useState<StatusEnum>(StatusEnum.doing);
  const style: any = {};
  const html: any = document.documentElement;

  if (!survey) {
    return <Loading />;
  }

  let isMobile = false;
  if (window.matchMedia("(max-width: 756px)").matches) {
    style.margin = 0;
    style.borderRadius = 0;
    if (survey.backgroundImage) {
      style.backgroundColor = "transparent";
      isMobile = true;
    }
  }

  if (survey.backgroundColor) {
    html.style.backgroundColor = survey.backgroundColor;
  }

  const img =
    isMobile && survey.backgroundImageMobile
      ? survey.backgroundImageMobile || survey.backgroundImage
      : survey.backgroundImage || survey.backgroundImageMobile;
  const size =
    isMobile && survey.backgroundImageSizeMobile
      ? survey.backgroundImageSizeMobile
      : survey.backgroundImageSize;

  if (img) {
    html.style.backgroundImage = `url('${img}')`;
    html.style.backgroundSize =
      size === "cover" || size === "coverfixed" ? "cover" : "";
    if (size === "coverfixed") {
      html.style.backgroundAttachment = "fixed";
    }
    if (size === "pattern300") {
      html.style.backgroundSize = "300px";
    }
    if (size === "pattern100") {
      html.style.backgroundSize = "100px";
    }
    if (size === "pattern100%") {
      html.style.backgroundSize = "100%";
    }
  }
  if (survey.backgroundColor) {
    style.marginTop = 40;
    style.boxShadow = "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)";
  }
  if (survey.surveyBackgroundColor) {
    style.backgroundColor = survey.surveyBackgroundColor;
  }

  const setCurrentPage = (page: number) => {
    window.scrollTo(0, 0);
    _setCurrentPage(page);
  };

  const validateAndComplete = (e: any) => {
    if (currentPage < survey.pages.length - 1) {
      setCurrentPage(currentPage + 1);
    } else {
      const [form, success] = completeSurvey(survey);
      ResponseService.save(survey.id, form).then((resp: any) => {
        if (success) {
          setStatus(StatusEnum.success);
        } else {
          setStatus(StatusEnum.error);
        }
      });
    }
  };

  const checkKeyDown = (e: any) => {
    if (e.key === "Enter") e.preventDefault();
  };

  const buttonColor: any = {};
  if (survey.surveyButtonsColor) {
    buttonColor.backgroundColor = survey.surveyButtonsColor;
  }
  const barColor: any = {};
  if (survey.surveyBarColor) {
    barColor.backgroundColor = survey.surveyBarColor1;
    const bar: any = document.querySelector("#linearprogress > span");
    if (bar) {
      bar.style.backgroundColor = survey.surveyBarColor2;
    }
  }

  return (
    <SurveyContainer className="survey" style={style}>
      {/* <SurveyHeader
        survey={survey}
        currentPage={currentPage}
        isMobile={isMobile}
      /> */}
      {currentPage !== undefined && survey.pages.length > 1 && (
        <Chip
          label={`Pagina ${currentPage + 1} / ${survey.pages.length}`}
          variant="outlined"
          style={{
            ...chipFloating,
            boxShadow: isMobile
              ? "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"
              : "",
          }}
        />
      )}
      {status === StatusEnum.doing && (
        <>
          {survey.pages
            .map((c: any, indexPage: number) => {
              return { ...c, indexPage };
            })
            .filter((_c: any, index: number) => index === currentPage)
            .map((c: any) => {
              return (
                <ValidatorForm
                  onSubmit={validateAndComplete}
                  onKeyDown={checkKeyDown}
                  onError={(errors) => console.log("FORM ERRORS", errors)}
                >
                  <SurveyPage
                    key={c.indexPage}
                    page={c}
                    indexPage={c.indexPage}
                    idSurvey={survey.id}
                    survey={survey}
                    production
                    isMobile={isMobile}
                  />
                  <div
                    style={{
                      padding: isMobile ? 20 : 0,
                      margin: isMobile ? -20 : 0,
                    }}
                  >
                    {survey.pages.length > 1 && (
                      <LinearProgress
                        id="linearprogress"
                        sx={{ height: 10 }}
                        style={{ margin: 20, ...barColor }}
                        variant="determinate"
                        value={(currentPage / (survey.pages.length - 1)) * 100}
                      />
                    )}
                    <SurveyButtonSheet>
                      {currentPage > 0 && (
                        <Button
                          size="large"
                          style={{ color: "black" }}
                          onClick={() => setCurrentPage(currentPage - 1)}
                        >
                          Indietro
                        </Button>
                      )}

                      {currentPage < survey.pages.length - 1 && (
                        <Button
                          variant="contained"
                          size="large"
                          color="success"
                          type="submit"
                          style={buttonColor}
                        >
                          Avanti
                        </Button>
                      )}
                      {currentPage === survey.pages.length - 1 && (
                        <Button
                          fullWidth={isMobile && survey.pages.length === 1}
                          type="submit"
                          variant="contained"
                          size="large"
                          color="success"
                          style={buttonColor}
                        >
                          Conferma e invia
                        </Button>
                      )}
                    </SurveyButtonSheet>
                  </div>
                </ValidatorForm>
              );
            })}
        </>
      )}
      {status === StatusEnum.success && (
        <SurveyPage
          feedbackPage="success_page"
          production
          isQuiz={survey.isQuiz}
          page={survey.success_page}
          indexPage={survey.pages.length}
          idSurvey={survey.id}
          survey={survey}
          isMobile={isMobile}
        />
      )}
      {status === StatusEnum.error && (
        <SurveyPage
          feedbackPage="error_page"
          production
          isQuiz={survey.isQuiz}
          page={survey.error_page}
          indexPage={survey.pages.length}
          idSurvey={survey.id}
          survey={survey}
          isMobile={isMobile}
          retrySurvey={retrySurvey}
        />
      )}
    </SurveyContainer>
  );
};

export const NoContent = ({ message }: any) => {
  return (
    <div>
      <div
        style={{
          margin: "auto",
          marginTop: 100,
          background: "#8BC34A",
          borderRadius: 8,
          padding: 15,
          maxWidth: 400,
          textAlign: "center",
        }}
      >
        <h2>{message}</h2>
      </div>
    </div>
  );
};

export default PreviewView;
